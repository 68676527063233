import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './App.css';
import axios from 'axios';
import Tilt from 'react-parallax-tilt';
import Moment from 'react-moment';
import { Card, Row, Col, Container, Image } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Fade from 'react-reveal/Fade';
import Plx from 'react-plx';
import { ExternalLink } from 'react-external-link';

const Home = () => {

    const parallaxData = [
        {
          start: 'self',
          end: 1200,
          properties: [
            {
              startValue: -10,
              endValue: 25,
              property: 'translateX',
              unit: 'vw',
            },
          ],
        },
      ];
      const parallaxData2 = [
        {
          start: 'self',
          end: 1600,
          properties: [
            {
              startValue: 10,
              endValue: -25,
              property: 'translateX',
              unit: 'vw',
            },
          ],
        },
      ];
      const parallaxData3 = [
        {
          start: 'self',
          end: 2400,
          properties: [
            {
              startValue: -10,
              endValue: 25,
              property: 'translateX',
              unit: 'vw',
            },
          ],
        },
      ];

    const [data, setData] = useState({posts: []});
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('https://product-lab-main.herokuapp.com/ghost/api/v3/content/posts/?key=f06429d3909d92f90c46971143&fields=title,slug,feature_image,featured,updated_at,custom_excerpt,reading_time&filter=featured:true&limit=4');
                setData(response.data);
            }
            catch(error) {
                console.log(error);
            }
        }
        fetchData();
    }, []);

    const rocketblocks = 'https://www.rocketblocks.me/product-management.php';
    return(
<div id="container">

<div id="landinganimation">
<svg id="hometextanimation" width="1341" height="165" viewBox="0 0 1341 165" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M67.6481 11.6C80.7921 11.6 92.1694 13.7907 101.78 18.172C111.532 22.5533 119.023 28.772 124.252 36.828C129.481 44.884 132.096 54.424 132.096 65.448C132.096 76.3307 129.481 85.8707 124.252 94.068C119.023 102.124 111.532 108.343 101.78 112.724C92.1694 116.964 80.7921 119.084 67.6481 119.084H37.7561V160H3.41211V11.6H67.6481ZM65.7401 91.1C76.0574 91.1 83.9014 88.9093 89.2721 84.528C94.6428 80.0053 97.3281 73.6453 97.3281 65.448C97.3281 57.1093 94.6428 50.7493 89.2721 46.368C83.9014 41.8453 76.0574 39.584 65.7401 39.584H37.7561V91.1H65.7401Z" stroke-width="5"/>
<path d="M185.871 60.996C189.829 55.484 195.129 51.3147 201.771 48.488C208.555 45.6613 216.329 44.248 225.091 44.248V74.776C221.417 74.4933 218.943 74.352 217.671 74.352C208.202 74.352 200.782 77.0373 195.411 82.408C190.041 87.6373 187.355 95.552 187.355 106.152V160H154.283V45.944H185.871V60.996Z"  stroke-width="5"/>
<path d="M297.993 161.696C285.979 161.696 275.167 159.223 265.557 154.276C256.087 149.188 248.667 142.192 243.297 133.288C237.926 124.384 235.241 114.279 235.241 102.972C235.241 91.6653 237.926 81.56 243.297 72.656C248.667 63.752 256.087 56.8267 265.557 51.88C275.167 46.792 285.979 44.248 297.993 44.248C310.006 44.248 320.747 46.792 330.217 51.88C339.686 56.8267 347.106 63.752 352.477 72.656C357.847 81.56 360.533 91.6653 360.533 102.972C360.533 114.279 357.847 124.384 352.477 133.288C347.106 142.192 339.686 149.188 330.217 154.276C320.747 159.223 310.006 161.696 297.993 161.696ZM297.993 134.56C306.473 134.56 313.398 131.733 318.769 126.08C324.281 120.285 327.037 112.583 327.037 102.972C327.037 93.3613 324.281 85.7293 318.769 80.076C313.398 74.2813 306.473 71.384 297.993 71.384C289.513 71.384 282.517 74.2813 277.005 80.076C271.493 85.7293 268.737 93.3613 268.737 102.972C268.737 112.583 271.493 120.285 277.005 126.08C282.517 131.733 289.513 134.56 297.993 134.56Z"  stroke-width="5"/>
<path d="M498.815 2.69598V160H467.227V146.856C459.029 156.749 447.157 161.696 431.611 161.696C420.869 161.696 411.117 159.293 402.355 154.488C393.733 149.683 386.949 142.828 382.003 133.924C377.056 125.02 374.583 114.703 374.583 102.972C374.583 91.2413 377.056 80.924 382.003 72.02C386.949 63.116 393.733 56.2613 402.355 51.456C411.117 46.6507 420.869 44.248 431.611 44.248C446.168 44.248 457.545 48.8413 465.743 58.028V2.69598H498.815ZM437.335 134.56C445.673 134.56 452.599 131.733 458.111 126.08C463.623 120.285 466.379 112.583 466.379 102.972C466.379 93.3613 463.623 85.7293 458.111 80.076C452.599 74.2813 445.673 71.384 437.335 71.384C428.855 71.384 421.859 74.2813 416.347 80.076C410.835 85.7293 408.079 93.3613 408.079 102.972C408.079 112.583 410.835 120.285 416.347 126.08C421.859 131.733 428.855 134.56 437.335 134.56Z"  stroke-width="5"/>
<path d="M644.328 45.944V160H612.952V146.432C608.571 151.379 603.341 155.195 597.264 157.88C591.187 160.424 584.615 161.696 577.548 161.696C562.567 161.696 550.694 157.385 541.932 148.764C533.169 140.143 528.788 127.352 528.788 110.392V45.944H561.86V105.516C561.86 123.889 569.562 133.076 584.968 133.076C592.883 133.076 599.243 130.532 604.048 125.444C608.853 120.215 611.256 112.512 611.256 102.336V45.944H644.328Z"  stroke-width="5"/>
<path d="M730.087 161.696C717.932 161.696 706.979 159.223 697.227 154.276C687.616 149.188 680.055 142.192 674.543 133.288C669.172 124.384 666.487 114.279 666.487 102.972C666.487 91.6653 669.172 81.56 674.543 72.656C680.055 63.752 687.616 56.8267 697.227 51.88C706.979 46.792 717.932 44.248 730.087 44.248C742.1 44.248 752.559 46.792 761.463 51.88C770.508 56.8267 777.08 63.964 781.179 73.292L755.527 87.072C749.591 76.6133 741.04 71.384 729.875 71.384C721.253 71.384 714.116 74.2107 708.463 79.864C702.809 85.5173 699.983 93.22 699.983 102.972C699.983 112.724 702.809 120.427 708.463 126.08C714.116 131.733 721.253 134.56 729.875 134.56C741.181 134.56 749.732 129.331 755.527 118.872L781.179 132.864C777.08 141.909 770.508 148.976 761.463 154.064C752.559 159.152 742.1 161.696 730.087 161.696Z"  stroke-width="5"/>
<path d="M875.441 154.488C872.19 156.891 868.162 158.728 863.357 160C858.693 161.131 853.746 161.696 848.517 161.696C834.949 161.696 824.42 158.233 816.929 151.308C809.58 144.383 805.905 134.207 805.905 120.78V73.928H788.309V48.488H805.905V20.716H838.977V48.488H867.385V73.928H838.977V120.356C838.977 125.161 840.178 128.907 842.581 131.592C845.125 134.136 848.658 135.408 853.181 135.408C858.41 135.408 862.862 133.995 866.537 131.168L875.441 154.488Z"  stroke-width="5"/>
<path d="M956.377 11.6H990.721V132.016H1065.13V160H956.377V11.6Z"  stroke-width="5"/>
<path d="M1129.24 44.248C1146.9 44.248 1160.47 48.488 1169.94 56.968C1179.41 65.3067 1184.14 77.956 1184.14 94.916V160H1153.19V145.796C1146.97 156.396 1135.38 161.696 1118.42 161.696C1109.66 161.696 1102.03 160.212 1095.53 157.244C1089.17 154.276 1084.29 150.177 1080.9 144.948C1077.51 139.719 1075.81 133.783 1075.81 127.14C1075.81 116.54 1079.77 108.201 1087.68 102.124C1095.74 96.0467 1108.11 93.008 1124.78 93.008H1151.07C1151.07 85.8 1148.88 80.288 1144.5 76.472C1140.12 72.5147 1133.55 70.536 1124.78 70.536C1118.71 70.536 1112.7 71.5253 1106.76 73.504C1100.97 75.3413 1096.02 77.8853 1091.92 81.136L1080.05 58.028C1086.27 53.6467 1093.69 50.2547 1102.31 47.852C1111.08 45.4493 1120.05 44.248 1129.24 44.248ZM1126.69 139.436C1132.35 139.436 1137.36 138.164 1141.74 135.62C1146.13 132.935 1149.24 129.048 1151.07 123.96V112.3H1128.39C1114.82 112.3 1108.04 116.752 1108.04 125.656C1108.04 129.896 1109.66 133.288 1112.91 135.832C1116.3 138.235 1120.9 139.436 1126.69 139.436Z"  stroke-width="5"/>
<path d="M1281.49 44.248C1292.09 44.248 1301.7 46.7213 1310.32 51.668C1319.09 56.4733 1325.94 63.328 1330.89 72.232C1335.83 80.9947 1338.31 91.2413 1338.31 102.972C1338.31 114.703 1335.83 125.02 1330.89 133.924C1325.94 142.687 1319.09 149.541 1310.32 154.488C1301.7 159.293 1292.09 161.696 1281.49 161.696C1265.8 161.696 1253.86 156.749 1245.66 146.856V160H1214.08V2.69598H1247.15V58.028C1255.49 48.8413 1266.93 44.248 1281.49 44.248ZM1275.77 134.56C1284.25 134.56 1291.17 131.733 1296.54 126.08C1302.06 120.285 1304.81 112.583 1304.81 102.972C1304.81 93.3613 1302.06 85.7293 1296.54 80.076C1291.17 74.2813 1284.25 71.384 1275.77 71.384C1267.29 71.384 1260.29 74.2813 1254.78 80.076C1249.41 85.7293 1246.72 93.3613 1246.72 102.972C1246.72 112.583 1249.41 120.285 1254.78 126.08C1260.29 131.733 1267.29 134.56 1275.77 134.56Z"  stroke-width="5"/>
</svg>

<h3 id="athcs">At Harvard Computer Society</h3>

<svg id="homegraphic" width="934" height="528" viewBox="0 0 934 528" fill="none">
<g id="homegraphic">
<g clip-path="url(#clip0_430_66)">
<path id="whiteboard" d="M220 19C214.698 19.006 209.614 21.1151 205.865 24.8645C202.115 28.614 200.006 33.6975 200 39V232C200.006 237.302 202.115 242.386 205.865 246.135C209.614 249.885 214.698 251.994 220 252H823C828.302 251.994 833.386 249.885 837.135 246.135C840.885 242.386 842.994 237.302 843 232V39C842.994 33.6975 840.885 28.6139 837.135 24.8645C833.386 21.1151 828.302 19.006 823 19H220Z" fill="white"/>
<g id="step3group">
<path id="Vector" d="M605.432 126.672H526.567C526.18 126.673 525.809 126.519 525.534 126.246C525.26 125.972 525.106 125.601 525.105 125.214C525.105 124.826 525.259 124.455 525.532 124.18C525.806 123.906 526.177 123.752 526.564 123.752H605.432C605.819 123.751 606.191 123.905 606.465 124.178C606.739 124.452 606.893 124.823 606.894 125.21C606.894 125.598 606.741 125.969 606.467 126.244C606.193 126.518 605.822 126.672 605.435 126.672H605.432Z" fill="#E6E6E6"/>
<path id="Vector_2" d="M605.432 139.816H526.567C526.18 139.817 525.809 139.663 525.534 139.39C525.26 139.116 525.106 138.745 525.105 138.358C525.105 137.97 525.259 137.599 525.532 137.324C525.806 137.05 526.177 136.896 526.564 136.896H605.432C605.819 136.896 606.19 137.049 606.464 137.323C606.738 137.597 606.892 137.969 606.892 138.356C606.892 138.743 606.738 139.115 606.464 139.389C606.19 139.663 605.819 139.816 605.432 139.816H605.432Z" fill="#E6E6E6"/>
<path id="Vector_3" d="M605.432 152.96H526.567C526.18 152.961 525.809 152.807 525.534 152.534C525.26 152.26 525.106 151.889 525.105 151.502C525.105 151.114 525.259 150.743 525.532 150.468C525.806 150.194 526.177 150.04 526.564 150.04H605.432C605.819 150.039 606.191 150.193 606.465 150.466C606.739 150.74 606.893 151.111 606.894 151.498C606.894 151.886 606.741 152.257 606.467 152.532C606.193 152.806 605.822 152.96 605.435 152.96H605.432Z" fill="#E6E6E6"/>
<path id="Vector_4" d="M605.432 166.104H526.568C526.181 166.104 525.81 165.95 525.537 165.677C525.264 165.403 525.11 165.032 525.109 164.646C525.109 164.259 525.262 163.888 525.535 163.614C525.808 163.34 526.178 163.185 526.565 163.184H605.432C605.819 163.185 606.189 163.339 606.462 163.613C606.735 163.886 606.889 164.257 606.889 164.644C606.889 165.031 606.735 165.402 606.462 165.675C606.189 165.949 605.819 166.103 605.432 166.104H605.432Z" fill="#E6E6E6"/>
<path id="Vector_5" d="M605.432 179.249H526.568C526.181 179.249 525.809 179.095 525.535 178.821C525.261 178.547 525.107 178.175 525.107 177.788C525.107 177.401 525.261 177.029 525.535 176.755C525.809 176.481 526.181 176.328 526.568 176.328H605.432C605.819 176.327 606.191 176.481 606.465 176.754C606.739 177.028 606.894 177.399 606.894 177.786C606.894 178.174 606.741 178.545 606.467 178.82C606.194 179.094 605.822 179.248 605.435 179.249H605.432Z" fill="#E6E6E6"/>
<path id="Vector_6" d="M624 195H508C505.614 194.997 503.326 194.048 501.639 192.361C499.952 190.674 499.003 188.386 499 186V117C499.003 114.614 499.952 112.326 501.639 110.639C503.326 108.952 505.614 108.003 508 108H624C626.386 108.003 628.674 108.952 630.361 110.639C632.048 112.326 632.997 114.614 633 117V186C632.997 188.386 632.048 190.674 630.361 192.361C628.674 194.048 626.386 194.997 624 195V195ZM508 110C506.144 110.002 504.365 110.74 503.053 112.053C501.74 113.365 501.002 115.144 501 117V186C501.002 187.856 501.74 189.635 503.053 190.947C504.365 192.26 506.144 192.998 508 193H624C625.856 192.998 627.635 192.26 628.947 190.947C630.26 189.635 630.998 187.856 631 186V117C630.998 115.144 630.26 113.365 628.947 112.053C627.635 110.74 625.856 110.002 624 110H508Z" fill="#E6E6E6"/>
<path id="Vector_7" d="M565.531 90.739C561.198 90.739 556.963 89.4542 553.36 87.047C549.757 84.6399 546.95 81.2185 545.292 77.2156C543.634 73.2127 543.2 68.808 544.045 64.5585C544.89 60.309 546.977 56.4056 550.04 53.3419C553.104 50.2782 557.008 48.1918 561.257 47.3465C565.506 46.5012 569.911 46.9351 573.914 48.5931C577.917 50.2512 581.338 53.059 583.746 56.6616C586.153 60.2641 587.437 64.4996 587.437 68.8323C587.437 74.6423 585.129 80.2144 581.021 84.3227C576.913 88.431 571.341 90.739 565.531 90.739V90.739ZM565.531 49.8465C561.776 49.8465 558.105 50.96 554.983 53.0461C551.861 55.1323 549.427 58.0975 547.99 61.5667C546.553 65.0359 546.177 68.8533 546.91 72.5362C547.642 76.2191 549.45 79.6021 552.106 82.2573C554.761 84.9125 558.144 86.7208 561.827 87.4533C565.51 88.1859 569.327 87.8099 572.796 86.373C576.265 84.936 579.231 82.5025 581.317 79.3803C583.403 76.2581 584.517 72.5874 584.517 68.8323V68.8323C584.511 63.7987 582.509 58.973 578.949 55.4137C575.39 51.8545 570.564 49.8523 565.531 49.8465V49.8465Z" fill="#CCCCCC"/>
<path id="Vector_8" d="M561.868 73.4144C562.762 73.9428 563.776 74.2316 564.814 74.253C567.127 74.253 567.843 72.7798 567.821 71.6759C567.801 69.8132 566.123 69.016 564.386 69.016H563.382V67.6653H564.386C565.695 67.6653 567.352 66.9908 567.352 65.4148C567.352 64.3508 566.676 63.4095 565.019 63.4095C564.066 63.4364 563.141 63.7429 562.361 64.2909L561.89 62.9816C562.924 62.3077 564.133 61.9517 565.367 61.9577C567.986 61.9577 569.172 63.5122 569.172 65.1295C569.158 65.8471 568.911 66.5407 568.468 67.1053C568.025 67.6699 567.41 68.0748 566.716 68.2587V68.3001C567.538 68.419 568.29 68.8294 568.835 69.4564C569.379 70.0835 569.68 70.8854 569.683 71.7158C569.683 73.8438 568.026 75.7064 564.835 75.7064C563.623 75.722 562.428 75.4107 561.378 74.805L561.868 73.4144Z" fill="#CCCCCC"/>
<path id="arrow" d="M693.599 130.703C693.543 130.587 693.471 130.479 693.385 130.382V130.382L682.432 119.429C682.297 119.293 682.136 119.185 681.958 119.112C681.781 119.038 681.591 119 681.399 119C681.207 119 681.017 119.038 680.84 119.111C680.662 119.184 680.501 119.292 680.366 119.428C680.23 119.563 680.122 119.725 680.049 119.902C679.975 120.079 679.938 120.269 679.938 120.461C679.938 120.653 679.976 120.843 680.05 121.02C680.123 121.198 680.231 121.359 680.367 121.494L688.827 129.955H651.46C651.073 129.955 650.702 130.108 650.428 130.382C650.154 130.656 650 131.028 650 131.415C650 131.802 650.154 132.174 650.428 132.448C650.702 132.722 651.073 132.875 651.46 132.875H688.827L681.097 140.606C680.961 140.741 680.853 140.902 680.779 141.079C680.705 141.257 680.667 141.447 680.667 141.639C680.667 141.831 680.705 142.021 680.778 142.199C680.851 142.376 680.959 142.537 681.095 142.673C681.231 142.809 681.392 142.917 681.569 142.99C681.747 143.063 681.937 143.101 682.129 143.101C682.321 143.101 682.511 143.062 682.689 142.989C682.866 142.915 683.027 142.807 683.162 142.671L693.385 132.449L693.386 132.448C693.609 132.221 693.75 131.927 693.789 131.612C693.828 131.296 693.761 130.977 693.599 130.703V130.703Z" fill="#CCCCCC"/>
</g>
<g id="step1group">
<path id="Vector_9" d="M283.067 93.739C278.734 93.739 274.499 92.4542 270.896 90.0471C267.294 87.6399 264.486 84.2186 262.828 80.2156C261.17 76.2127 260.736 71.808 261.581 67.5585C262.426 63.309 264.513 59.4056 267.576 56.3419C270.64 53.2782 274.544 51.1918 278.793 50.3465C283.043 49.5012 287.447 49.9351 291.45 51.5931C295.453 53.2512 298.874 56.059 301.282 59.6616C303.689 63.2641 304.974 67.4995 304.974 71.8323V71.8323C304.967 77.6403 302.657 83.2086 298.55 87.3155C294.443 91.4224 288.875 93.7326 283.067 93.739V93.739ZM283.067 52.8465C279.312 52.8465 275.641 53.96 272.519 56.0462C269.397 58.1323 266.963 61.0975 265.526 64.5667C264.089 68.0359 263.713 71.8533 264.446 75.5362C265.178 79.2191 266.987 82.6021 269.642 85.2573C272.297 87.9125 275.68 89.7207 279.363 90.4533C283.046 91.1859 286.863 90.8099 290.332 89.3729C293.802 87.9359 296.767 85.5025 298.853 82.3803C300.939 79.2581 302.053 75.5873 302.053 71.8323V71.8323C302.047 66.7987 300.045 61.9729 296.486 58.4136C292.926 54.8543 288.101 52.8522 283.067 52.8465Z" fill="#CCCCCC"/>
<path id="Vector_10" d="M282.582 66.8605H282.541L280.229 68.1084L279.882 66.7378L282.786 65.1832H284.321V78.4812H282.582V66.8605Z" fill="#CCCCCC"/>
<path id="Vector_11" d="M313.994 127.996H267.259C266.872 127.996 266.5 127.842 266.227 127.568C265.953 127.294 265.799 126.922 265.799 126.535C265.799 126.148 265.953 125.776 266.227 125.502C266.5 125.229 266.872 125.075 267.259 125.075H313.994C314.381 125.075 314.752 125.229 315.026 125.502C315.3 125.776 315.454 126.148 315.454 126.535C315.454 126.922 315.3 127.294 315.026 127.568C314.752 127.842 314.381 127.996 313.994 127.996H313.994Z" fill="#E6E6E6"/>
<path id="Vector_12" d="M313.993 144.06H267.259C266.872 144.061 266.5 143.907 266.226 143.634C265.952 143.36 265.797 142.989 265.797 142.602C265.796 142.214 265.95 141.843 266.224 141.568C266.497 141.294 266.868 141.14 267.256 141.14H313.993C314.381 141.139 314.752 141.293 315.026 141.566C315.301 141.84 315.455 142.211 315.455 142.598C315.456 142.986 315.302 143.357 315.029 143.632C314.755 143.906 314.384 144.06 313.996 144.06H313.993Z" fill="#E6E6E6"/>
<path id="Vector_13" d="M313.994 160.125H267.259C266.872 160.125 266.5 159.971 266.227 159.698C265.953 159.424 265.799 159.052 265.799 158.665C265.799 158.278 265.953 157.906 266.227 157.632C266.5 157.358 266.872 157.204 267.259 157.204H313.994C314.185 157.204 314.375 157.242 314.553 157.315C314.73 157.388 314.891 157.496 315.027 157.631C315.162 157.767 315.27 157.927 315.344 158.105C315.417 158.282 315.455 158.472 315.456 158.663C315.456 158.855 315.418 159.045 315.345 159.222C315.272 159.4 315.164 159.561 315.029 159.697C314.893 159.832 314.733 159.94 314.555 160.014C314.378 160.087 314.188 160.125 313.997 160.125H313.994Z" fill="#E6E6E6"/>
<g id="sticky1">
<path id="Vector_14" d="M241.029 217.1C240.615 216.576 240.326 215.965 240.184 215.312C240.042 214.659 240.05 213.983 240.209 213.334C244 197.455 244 180.907 240.208 165.028C240.05 164.379 240.042 163.703 240.184 163.05C240.326 162.398 240.615 161.786 241.03 161.262C241.435 160.746 241.953 160.328 242.544 160.042C243.135 159.756 243.783 159.608 244.44 159.61H284.967C286.003 159.612 287.004 159.981 287.793 160.652C288.581 161.324 289.106 162.253 289.274 163.275C291.917 180.445 291.917 197.918 289.274 215.088C289.106 216.109 288.581 217.039 287.793 217.71C287.004 218.381 286.003 218.751 284.967 218.752H244.44C243.783 218.755 243.135 218.607 242.544 218.32C241.953 218.034 241.435 217.617 241.029 217.1Z" fill="#FFD600"/>
<path id="Vector_15" d="M277.147 181.432H255.798C255.002 181.431 254.24 181.114 253.677 180.552C253.115 179.989 252.799 179.227 252.798 178.432V177.158C252.799 176.362 253.115 175.6 253.677 175.037C254.24 174.475 255.002 174.158 255.798 174.158H277.147C277.943 174.158 278.705 174.475 279.268 175.037C279.83 175.6 280.146 176.362 280.147 177.158V178.432C280.146 179.227 279.83 179.989 279.268 180.552C278.705 181.114 277.943 181.431 277.147 181.432Z" fill="white"/>
<path id="Vector_16" d="M277.147 193.979H255.798C255.002 193.978 254.24 193.662 253.677 193.099C253.115 192.537 252.799 191.774 252.798 190.979V189.705C252.799 188.91 253.115 188.147 253.677 187.585C254.24 187.022 255.002 186.706 255.798 186.705H277.147C277.943 186.706 278.705 187.022 279.268 187.585C279.83 188.147 280.146 188.91 280.147 189.705V190.979C280.146 191.774 279.83 192.537 279.268 193.099C278.705 193.662 277.943 193.978 277.147 193.979V193.979Z" fill="white"/>
</g>
<path id="Vector_17" d="M253.161 129.105C253.677 129.123 254.186 128.985 254.623 128.709C255.059 128.434 255.403 128.033 255.608 127.56C255.814 127.087 255.873 126.562 255.776 126.055C255.68 125.548 255.433 125.082 255.068 124.717C254.703 124.352 254.237 124.105 253.73 124.009C253.223 123.913 252.698 123.971 252.225 124.177C251.752 124.383 251.351 124.726 251.076 125.163C250.8 125.599 250.662 126.108 250.68 126.624C250.703 127.275 250.971 127.893 251.432 128.353C251.892 128.814 252.51 129.082 253.161 129.105Z" fill="#E6E6E6"/>
<path id="Vector_18" d="M253.161 145.17C253.677 145.188 254.186 145.05 254.623 144.774C255.059 144.499 255.403 144.098 255.608 143.625C255.814 143.152 255.873 142.627 255.776 142.12C255.68 141.613 255.433 141.147 255.068 140.782C254.703 140.417 254.237 140.17 253.73 140.074C253.223 139.977 252.698 140.036 252.225 140.242C251.752 140.448 251.351 140.791 251.076 141.227C250.8 141.664 250.662 142.173 250.68 142.689C250.703 143.34 250.971 143.958 251.432 144.418C251.892 144.879 252.51 145.147 253.161 145.17V145.17Z" fill="#E6E6E6"/>
<path id="Vector_19" d="M253.161 161.235C253.677 161.253 254.186 161.115 254.623 160.839C255.059 160.564 255.403 160.163 255.608 159.69C255.814 159.217 255.873 158.692 255.776 158.185C255.68 157.678 255.433 157.212 255.068 156.847C254.703 156.482 254.237 156.235 253.73 156.139C253.223 156.042 252.698 156.101 252.225 156.307C251.752 156.512 251.351 156.856 251.076 157.292C250.8 157.729 250.662 158.238 250.68 158.754C250.703 159.405 250.971 160.023 251.432 160.483C251.892 160.944 252.51 161.212 253.161 161.235Z" fill="#E6E6E6"/>
</g>
<g id="step2group">
<g id="num2">
<path id="Vector_23" d="M418.758 90.739C414.425 90.739 410.19 89.4542 406.588 87.047C402.985 84.6399 400.177 81.2185 398.519 77.2156C396.861 73.2127 396.427 68.808 397.273 64.5585C398.118 60.309 400.204 56.4056 403.268 53.3419C406.332 50.2782 410.235 48.1918 414.484 47.3465C418.734 46.5012 423.139 46.9351 427.142 48.5931C431.145 50.2512 434.566 53.059 436.973 56.6616C439.38 60.2641 440.665 64.4996 440.665 68.8323C440.665 71.7091 440.098 74.5578 438.997 77.2156C437.897 79.8735 436.283 82.2885 434.249 84.3227C432.214 86.3569 429.799 87.9706 427.142 89.0715C424.484 90.1724 421.635 90.739 418.758 90.739V90.739ZM418.758 49.8465C415.003 49.8465 411.332 50.96 408.21 53.0461C405.088 55.1323 402.655 58.0975 401.218 61.5667C399.781 65.0359 399.405 68.8533 400.137 72.5362C400.87 76.2191 402.678 79.6021 405.333 82.2573C407.988 84.9125 411.371 86.7208 415.054 87.4533C418.737 88.1859 422.555 87.8099 426.024 86.373C429.493 84.936 432.458 82.5025 434.544 79.3803C436.631 76.2581 437.744 72.5874 437.744 68.8323V68.8323C437.738 63.7987 435.736 58.973 432.177 55.4137C428.618 51.8544 423.792 49.8523 418.758 49.8465V49.8465Z" fill="#CCCCCC"/>
<path id="Vector_24" d="M414.884 75.594V74.4887L416.294 73.1181C419.692 69.8863 421.226 68.1662 421.246 66.1624C421.282 65.8088 421.239 65.4515 421.118 65.1171C420.998 64.7826 420.804 64.4794 420.551 64.2301C420.297 63.9807 419.991 63.7915 419.655 63.6764C419.319 63.5613 418.961 63.5234 418.608 63.5653C417.565 63.6036 416.568 64.0006 415.784 64.6891L415.21 63.4212C416.267 62.5418 417.601 62.0635 418.976 62.0706C419.505 62.0236 420.038 62.0897 420.539 62.2646C421.04 62.4394 421.499 62.719 421.884 63.0847C422.269 63.4505 422.572 63.894 422.772 64.3858C422.972 64.8776 423.066 65.4064 423.046 65.9371C423.046 68.3916 421.266 70.3769 418.464 73.0767L417.4 74.0594V74.0993H423.374V75.594H414.884Z" fill="#CCCCCC"/>
</g>
<g id="sticky2">
<path id="Vector_20" d="M348.946 199.49C348.532 198.966 348.243 198.355 348.101 197.702C347.959 197.05 347.967 196.373 348.126 195.724C351.917 179.845 351.917 163.297 348.125 147.418C347.967 146.77 347.959 146.093 348.101 145.44C348.243 144.788 348.532 144.176 348.946 143.652C349.352 143.136 349.87 142.718 350.461 142.432C351.052 142.146 351.7 141.998 352.357 142H392.884C393.92 142.002 394.921 142.371 395.71 143.043C396.498 143.714 397.023 144.643 397.191 145.665C399.834 162.835 399.834 180.308 397.191 197.478C397.023 198.5 396.498 199.429 395.71 200.1C394.921 200.771 393.92 201.141 392.884 201.143H352.357C351.7 201.145 351.052 200.997 350.461 200.711C349.87 200.424 349.352 200.007 348.946 199.49Z" fill="#FFD600"/>
<path id="Vector_21" d="M385.064 163.822H363.715C362.919 163.821 362.157 163.505 361.594 162.942C361.032 162.38 360.716 161.617 360.715 160.822V159.548C360.716 158.752 361.032 157.99 361.594 157.427C362.157 156.865 362.919 156.549 363.715 156.548H385.064C385.86 156.549 386.622 156.865 387.185 157.427C387.747 157.99 388.063 158.752 388.064 159.548V160.822C388.063 161.617 387.747 162.38 387.185 162.942C386.622 163.505 385.86 163.821 385.064 163.822Z" fill="white"/>
<path id="Vector_22" d="M385.064 176.369H363.715C362.919 176.368 362.157 176.052 361.594 175.489C361.032 174.927 360.716 174.164 360.715 173.369V172.095C360.716 171.3 361.032 170.537 361.594 169.975C362.157 169.412 362.919 169.096 363.715 169.095H385.064C385.86 169.096 386.622 169.412 387.185 169.975C387.747 170.537 388.063 171.3 388.064 172.095V173.369C388.063 174.165 387.747 174.927 387.185 175.489C386.622 176.052 385.86 176.368 385.064 176.369V176.369Z" fill="white"/>
</g>
<path id="sticky4" d="M434.951 232.581C432.35 215.689 432.35 198.498 434.951 181.606C435.115 180.6 435.632 179.686 436.408 179.026C437.184 178.365 438.169 178.002 439.187 178H479.06C479.706 177.998 480.344 178.143 480.926 178.425C481.507 178.707 482.017 179.118 482.415 179.626C482.823 180.142 483.107 180.743 483.247 181.385C483.387 182.027 483.379 182.693 483.223 183.331C479.493 198.953 479.493 215.234 483.222 230.856C483.378 231.494 483.387 232.16 483.247 232.802C483.107 233.444 482.823 234.045 482.415 234.561C482.017 235.069 481.507 235.48 480.926 235.762C480.344 236.043 479.706 236.189 479.06 236.187H439.187C438.169 236.185 437.184 235.821 436.408 235.161C435.632 234.501 435.115 233.586 434.951 232.581V232.581Z" fill="#FFD600"/>
<path id="sticky3" d="M428.951 152.581C426.35 135.689 426.35 118.498 428.951 101.606C429.115 100.6 429.632 99.6861 430.408 99.0257C431.184 98.3654 432.169 98.0019 433.187 98H473.06C473.706 97.998 474.344 98.1434 474.926 98.4252C475.507 98.707 476.017 99.1178 476.415 99.6263C476.823 100.142 477.107 100.743 477.247 101.385C477.387 102.027 477.379 102.693 477.223 103.331C473.493 118.953 473.493 135.234 477.222 150.856C477.378 151.494 477.387 152.16 477.247 152.802C477.107 153.444 476.823 154.045 476.415 154.561C476.017 155.069 475.507 155.48 474.926 155.762C474.344 156.043 473.706 156.189 473.06 156.187H433.187C432.169 156.185 431.184 155.821 430.408 155.161C429.632 154.501 429.115 153.586 428.951 152.581V152.581Z" fill="#FFD600"/>
</g>
<g id="girl">
<path id="Vector_25" d="M36.8758 365.036C45.2497 350.137 55.8545 336.607 68.322 324.916C69.0721 324.227 70.0425 323.826 71.0605 323.785C72.0785 323.744 73.078 324.065 73.8811 324.692L105.263 349.289C105.773 349.686 106.185 350.194 106.469 350.775C106.753 351.355 106.9 351.993 106.9 352.639C106.903 353.296 106.756 353.945 106.47 354.537C106.184 355.128 105.767 355.647 105.25 356.053C92.6778 366.047 82.6342 378.861 75.9324 393.457C75.6617 394.056 75.2578 394.585 74.7517 395.004C74.2456 395.423 73.6506 395.721 73.0119 395.875C72.3844 396.029 71.7301 396.038 71.0986 395.901C70.4671 395.764 69.8752 395.485 69.3679 395.085L37.9863 370.488C37.1857 369.858 36.6347 368.964 36.4313 367.966C36.228 366.967 36.3855 365.929 36.8758 365.036V365.036Z" fill="#CCCCCC"/>
<path id="Vector_26" d="M80.3825 361.141L63.9146 348.234C63.2892 347.743 62.8841 347.023 62.7884 346.234C62.6927 345.444 62.9142 344.649 63.4042 344.022L64.1271 343.1C64.6184 342.474 65.3378 342.069 66.1274 341.974C66.917 341.878 67.7123 342.099 68.3389 342.589L84.8068 355.497C85.4322 355.988 85.8373 356.708 85.933 357.497C86.0287 358.287 85.8072 359.082 85.3172 359.709L84.5943 360.631C84.103 361.256 83.3836 361.661 82.594 361.757C81.8044 361.853 81.009 361.631 80.3825 361.141V361.141Z" fill="white"/>
<path id="Vector_27" d="M72.7673 370.857L56.2994 357.95C55.6739 357.458 55.2689 356.739 55.1732 355.949C55.0775 355.16 55.2989 354.365 55.7889 353.738L56.5121 352.815C57.0035 352.19 57.7229 351.785 58.5125 351.689C59.3021 351.593 60.0974 351.815 60.7239 352.305L77.1919 365.212C77.8173 365.704 78.2224 366.423 78.3181 367.213C78.4138 368.002 78.1923 368.798 77.7024 369.424L76.9791 370.347C76.4878 370.972 75.7684 371.377 74.9788 371.473C74.1892 371.569 73.3938 371.347 72.7673 370.857V370.857Z" fill="white"/>
<path id="Vector_28" d="M51.3584 500.7L62.3674 506.096L88.4194 466.203L72.1724 458.239L51.3584 500.7Z" fill="#FFB8B8"/>
<path id="Vector_29" d="M50.3118 495.729L71.9911 506.356L71.992 506.357C73.8063 507.246 75.4277 508.484 76.7635 510C78.0993 511.516 79.1235 513.281 79.7775 515.192C80.4315 517.104 80.7025 519.126 80.5751 521.143C80.4476 523.159 79.9242 525.131 79.0348 526.945L78.8147 527.394L43.3193 509.995L50.3118 495.729Z" fill="#515151"/>
<path id="Vector_30" d="M160.452 519.51L172.712 519.509L178.544 472.221L160.45 472.222L160.452 519.51Z" fill="#FFB8B8"/>
<path id="Vector_31" d="M157.324 511.507L181.468 511.506H181.469C183.49 511.506 185.49 511.904 187.357 512.677C189.224 513.451 190.92 514.584 192.349 516.013C193.778 517.442 194.911 519.138 195.684 521.004C196.458 522.871 196.855 524.872 196.855 526.893V527.393L157.325 527.394L157.324 511.507Z" fill="#515151"/>
<path id="Vector_32" d="M134.118 193.156C147.682 193.156 158.679 182.16 158.679 168.595C158.679 155.03 147.682 144.034 134.118 144.034C120.553 144.034 109.557 155.03 109.557 168.595C109.557 182.16 120.553 193.156 134.118 193.156Z" fill="#FFB8B8"/>
<path id="Vector_33" d="M158.575 503.913C157.417 503.911 156.303 503.463 155.467 502.661C154.63 501.86 154.135 500.766 154.085 499.609L144.591 373.248C144.58 372.917 144.46 372.598 144.249 372.343C144.038 372.087 143.749 371.909 143.426 371.835C143.103 371.761 142.765 371.796 142.464 371.934C142.163 372.073 141.917 372.307 141.763 372.601L81.0312 488.543C80.4799 489.583 79.545 490.367 78.4249 490.729C77.3047 491.09 76.0878 491.001 75.0324 490.48L58.1025 482.015C57.0404 481.481 56.2321 480.549 55.8533 479.423C55.4745 478.296 55.5559 477.065 56.0798 475.999L92.3185 402.567L116.321 347.562C125.586 329.313 135.424 319.908 146.398 318.811C157.656 317.687 169.678 325.428 183.159 342.478L183.269 342.617L183.267 342.794L181.267 497.152C181.27 498.273 180.853 499.354 180.098 500.183C179.342 501.011 178.304 501.526 177.187 501.626L159.016 503.892C158.868 503.906 158.721 503.913 158.575 503.913Z" fill="#515151"/>
<path id="Vector_34" d="M108.195 356.445C109.532 355.62 110.671 354.513 111.533 353.2C112.395 351.887 112.959 350.401 113.185 348.847C113.411 347.293 113.293 345.708 112.84 344.204C112.387 342.701 111.61 341.315 110.564 340.144L114.267 242.288L96.2672 244.531L94.7494 339.731C92.8988 341.619 91.8053 344.121 91.676 346.762C91.5468 349.403 92.3908 352 94.0481 354.06C95.7055 356.12 98.061 357.501 100.668 357.941C103.276 358.38 105.954 357.848 108.195 356.445V356.445Z" fill="#FFB8B8"/>
<path id="Vector_35" d="M119.582 259.589L95.4097 256.842C94.7219 256.764 94.0591 256.538 93.4663 256.181C92.8735 255.823 92.3647 255.342 91.9746 254.771C91.5845 254.199 91.3222 253.55 91.2056 252.867C91.089 252.185 91.1208 251.486 91.2988 250.817L97.5394 227.379C97.959 223.863 99.7561 220.656 102.537 218.462C105.317 216.268 108.854 215.267 112.372 215.677C115.89 216.087 119.101 217.876 121.303 220.65C123.504 223.425 124.515 226.959 124.114 230.478L124.94 254.64C124.963 255.331 124.837 256.02 124.571 256.659C124.304 257.298 123.903 257.871 123.394 258.341C122.886 258.811 122.282 259.165 121.624 259.381C120.966 259.596 120.27 259.667 119.582 259.589L119.582 259.589Z" fill="#515151"/>
<path id="Vector_36" d="M223.708 197.989C223.585 198.528 223.505 199.077 223.469 199.629L180.511 224.411L170.07 218.4L158.938 232.972L176.389 245.41C177.799 246.415 179.496 246.935 181.227 246.893C182.958 246.851 184.628 246.248 185.987 245.176L230.284 210.23C232.283 210.97 234.46 211.084 236.525 210.557C238.59 210.029 240.446 208.885 241.846 207.277C243.245 205.669 244.122 203.674 244.359 201.555C244.597 199.437 244.184 197.296 243.175 195.418C242.166 193.541 240.61 192.014 238.712 191.043C236.815 190.071 234.667 189.7 232.553 189.979C230.44 190.258 228.462 191.174 226.882 192.605C225.302 194.036 224.195 195.914 223.708 197.989V197.989Z" fill="#FFB8B8"/>
<path id="Vector_37" d="M179.075 225.585L165.191 243.579C164.796 244.091 164.296 244.512 163.723 244.813C163.151 245.114 162.521 245.288 161.875 245.323C161.229 245.358 160.583 245.254 159.982 245.017C159.38 244.781 158.836 244.417 158.388 243.951L142.67 227.63C140.061 225.595 138.365 222.607 137.956 219.323C137.546 216.039 138.455 212.727 140.485 210.113C142.514 207.499 145.497 205.796 148.78 205.379C152.063 204.961 155.378 205.863 157.997 207.886L177.71 218.909C178.274 219.225 178.764 219.658 179.145 220.18C179.527 220.702 179.792 221.301 179.921 221.934C180.051 222.568 180.042 223.222 179.896 223.852C179.75 224.482 179.47 225.073 179.075 225.585H179.075Z" fill="#515151"/>
<path id="Vector_38" d="M190.401 350.13L163.747 220.301C162.48 214.131 158.871 208.691 153.678 205.126C148.484 201.561 142.111 200.148 135.897 201.183L127.008 202.665C123.92 203.179 120.964 204.297 118.308 205.954C115.652 207.612 113.348 209.776 111.529 212.323C108.637 216.371 106.69 221.015 105.829 225.914C104.969 230.813 105.217 235.842 106.555 240.633L121.268 293.288L110.349 349.7C110.227 350.326 110.257 350.972 110.435 351.583C110.614 352.195 110.936 352.756 111.375 353.218C111.814 353.68 112.358 354.03 112.96 354.239C113.562 354.448 114.205 354.511 114.836 354.421C120.302 353.638 129.313 352.522 139.661 351.914L145.268 330.288L147.548 351.57C160.261 351.215 174.06 351.838 185.445 354.795C186.103 354.971 186.796 354.976 187.457 354.809C188.118 354.643 188.725 354.31 189.222 353.843C189.718 353.376 190.087 352.789 190.293 352.14C190.5 351.49 190.537 350.798 190.401 350.13V350.13Z" fill="#FFD600"/>
<path id="Vector_39" d="M162.403 147.859C160.937 139.333 154.092 132.264 145.938 129.372C137.785 126.48 128.59 127.421 120.605 130.751C112.083 134.217 105.028 140.533 100.645 148.622C99.6062 148.158 98.6075 147.608 97.6592 146.979C92.9307 143.832 89.0937 139.35 83.978 136.883C74.9585 132.533 64.0542 135.425 55.2764 140.243C46.4985 145.061 38.7173 151.718 29.4448 155.499C20.1728 159.28 8.34131 159.467 1.32031 152.328C-0.40754 157.333 -0.548834 162.75 0.915691 167.84C2.38022 172.929 5.37923 177.442 9.50355 180.764C13.6279 184.085 18.6766 186.054 23.9609 186.4C29.2452 186.746 34.5077 185.454 39.0303 182.699C49.1895 176.511 54.8013 163.535 66.1963 160.12C72.5015 158.23 79.2725 159.752 85.6455 161.401C89.3996 162.481 93.2305 163.273 97.105 163.771C97.1504 170.763 99.7842 177.492 104.498 182.657C112.362 190.935 124.704 193.142 136.121 193.008L136.359 192.765C132.864 187.533 130.105 180.277 133.954 175.299C137.753 170.385 145.246 171.109 151.064 168.934C159.168 165.906 163.869 156.385 162.403 147.859Z" fill="#5A3A1A"/>
<path id="Vector_40" d="M205.916 528H35.916C35.6508 528 35.3964 527.895 35.2089 527.707C35.0214 527.52 34.916 527.265 34.916 527C34.916 526.735 35.0214 526.48 35.2089 526.293C35.3964 526.105 35.6508 526 35.916 526H205.916C206.181 526 206.436 526.105 206.623 526.293C206.811 526.48 206.916 526.735 206.916 527C206.916 527.265 206.811 527.52 206.623 527.707C206.436 527.895 206.181 528 205.916 528Z" fill="#CCCCCC"/>
</g>
<g id="guy">
<path id="Vector_41" d="M884.857 378.031C882.256 361.139 882.256 343.948 884.857 327.056C885.022 326.05 885.538 325.136 886.314 324.476C887.09 323.815 888.075 323.452 889.094 323.45H928.966C929.612 323.448 930.25 323.593 930.832 323.875C931.413 324.157 931.923 324.568 932.321 325.076C932.729 325.592 933.013 326.193 933.153 326.835C933.293 327.477 933.285 328.143 933.129 328.781C929.399 344.403 929.399 360.684 933.129 376.306C933.285 376.944 933.293 377.61 933.153 378.252C933.014 378.893 932.729 379.495 932.321 380.01C931.923 380.519 931.413 380.93 930.832 381.212C930.25 381.493 929.612 381.639 928.966 381.637H889.094C888.075 381.635 887.09 381.271 886.314 380.611C885.538 379.951 885.022 379.036 884.857 378.031V378.031Z" fill="#CCCCCC"/>
<path id="Vector_42" d="M917.424 349.361H896.5C895.705 349.36 894.942 349.044 894.38 348.482C893.817 347.919 893.501 347.157 893.5 346.361V345.189C893.501 344.394 893.817 343.631 894.38 343.069C894.942 342.506 895.705 342.19 896.5 342.189H917.424C918.219 342.19 918.982 342.506 919.544 343.069C920.106 343.631 920.423 344.394 920.424 345.189V346.361C920.423 347.157 920.106 347.919 919.544 348.482C918.982 349.044 918.219 349.36 917.424 349.361V349.361Z" fill="white"/>
<path id="Vector_43" d="M896.567 331.4C897.756 328.995 897.996 326.23 897.239 323.656C896.482 321.082 894.784 318.887 892.483 317.508C892.017 317.25 891.531 317.033 891.029 316.857L890.209 280.547L875.705 291.227L878.708 324.07C878.171 326.274 878.356 328.592 879.235 330.683C880.114 332.775 881.641 334.528 883.591 335.687C884.713 336.312 885.953 336.697 887.231 336.818C888.51 336.939 889.8 336.793 891.019 336.39C892.239 335.987 893.362 335.336 894.317 334.477C895.272 333.618 896.038 332.57 896.567 331.4V331.4Z" fill="#FFB8B8"/>
<path id="Vector_44" d="M890.457 315.277L880.061 315.904C878.952 315.932 877.873 315.541 877.039 314.81C876.205 314.078 875.677 313.06 875.559 311.956L866.927 247.01L852.977 207.393C851.799 204.092 851.929 200.466 853.338 197.258C853.993 195.712 854.956 194.316 856.17 193.157C857.383 191.997 858.821 191.098 860.395 190.514C866.748 188.205 873.704 191.861 875.9 198.663L890.087 244.084L894.462 310.417C894.55 311.619 894.174 312.81 893.412 313.744C892.935 314.331 892.308 314.78 891.598 315.042C891.231 315.175 890.847 315.255 890.457 315.277V315.277Z" fill="#515151"/>
<path id="Vector_45" d="M829.849 514.9L817.589 514.899L811.757 467.611L829.851 467.612L829.849 514.9Z" fill="#FFB8B8"/>
<path id="Vector_46" d="M832.975 526.784L793.444 526.782V526.282C793.444 524.262 793.842 522.261 794.615 520.394C795.389 518.528 796.522 516.831 797.951 515.403C799.38 513.974 801.076 512.84 802.943 512.067C804.809 511.294 806.81 510.896 808.831 510.896H808.832L832.976 510.897L832.975 526.784Z" fill="#515151"/>
<path id="Vector_47" d="M865.849 514.9L853.589 514.899L847.757 467.611L865.851 467.612L865.849 514.9Z" fill="#FFB8B8"/>
<path id="Vector_48" d="M868.975 526.784L829.444 526.782V526.282C829.444 524.262 829.842 522.261 830.615 520.394C831.389 518.528 832.522 516.831 833.951 515.403C835.38 513.974 837.076 512.84 838.943 512.067C840.809 511.294 842.81 510.896 844.831 510.896H844.832L868.976 510.897L868.975 526.784Z" fill="#515151"/>
<path id="Vector_49" d="M874.076 323.792L871.149 497.486C871.139 498.036 871.017 498.578 870.788 499.079C870.56 499.579 870.231 500.027 869.822 500.395C869.412 500.762 868.932 501.042 868.409 501.215C867.887 501.388 867.335 501.452 866.787 501.402L852.441 500.098C851.5 500.012 850.62 499.597 849.956 498.924C849.292 498.251 848.888 497.366 848.815 496.424L840.416 388.078C840.396 387.824 840.28 387.586 840.091 387.415C839.902 387.243 839.655 387.151 839.399 387.156C839.144 387.161 838.901 387.263 838.718 387.442C838.536 387.621 838.43 387.863 838.42 388.118L834.222 498.943C834.183 499.977 833.745 500.956 832.999 501.674C832.254 502.391 831.259 502.792 830.225 502.792H814.839C813.821 502.792 812.84 502.403 812.098 501.706C811.356 501.008 810.909 500.053 810.847 499.036L800.076 322.792L864.076 306.792L874.076 323.792Z" fill="#FFD600"/>
<path id="Vector_50" d="M827.655 160.696C841.219 160.696 852.216 149.699 852.216 136.135C852.216 122.57 841.219 111.574 827.655 111.574C814.09 111.574 803.094 122.57 803.094 136.135C803.094 149.699 814.09 160.696 827.655 160.696Z" fill="#FFB8B8"/>
<path id="Vector_51" d="M797.885 328.959C797.459 328.548 797.119 328.057 796.885 327.513C796.651 326.97 796.527 326.386 796.52 325.794L794.863 207.352C794.764 201.327 796.576 195.425 800.041 190.494C803.505 185.564 808.443 181.859 814.145 179.91C816.446 169.471 828.693 168.163 835.833 168.319C837.614 168.358 839.361 168.825 840.924 169.682C842.487 170.539 843.82 171.76 844.81 173.242L849.928 180.919L861.208 187.531C866.429 190.578 870.546 195.205 872.967 200.743C875.388 206.282 875.988 212.447 874.678 218.348L872.942 226.247C866.826 254.206 867.408 283.216 874.64 310.907L878.134 324.232C878.316 324.925 878.33 325.653 878.174 326.353C878.017 327.053 877.696 327.706 877.236 328.257C876.777 328.807 876.192 329.24 875.531 329.519C874.87 329.798 874.152 329.915 873.436 329.859L869.378 329.547C868.324 329.467 867.332 329.019 866.576 328.28C865.821 327.542 865.349 326.56 865.245 325.509L864.573 318.792C864.561 318.669 864.503 318.554 864.411 318.471C864.319 318.388 864.2 318.342 864.076 318.342C863.952 318.342 863.832 318.388 863.74 318.471C863.648 318.554 863.591 318.669 863.578 318.792L862.928 325.295C862.818 326.394 862.309 327.413 861.496 328.161C860.683 328.908 859.624 329.33 858.52 329.347L801.089 330.231C801.065 330.231 801.041 330.232 801.017 330.232C799.847 330.234 798.722 329.777 797.885 328.959V328.959Z" fill="#515151"/>
<path id="Vector_52" d="M829.576 150.292C829.677 153.764 832.087 155.773 835.047 157.591C838.006 159.41 841.465 160.198 844.855 160.959C845.31 161.201 845.82 161.321 846.335 161.309C846.85 161.296 847.353 161.151 847.796 160.887C848.239 160.623 848.606 160.25 848.863 159.803C849.119 159.355 849.256 158.85 849.26 158.334L852.368 146.511C853.468 142.734 854.268 138.877 854.761 134.974C855.51 127.465 853.73 119.387 848.489 113.958C843.248 108.529 834.158 106.6 827.878 110.783C820.424 101.263 786.354 122.296 794.758 128.916C796.253 130.094 798.539 128.603 800.437 128.459C802.335 128.315 804.461 128.648 805.667 130.121C807.242 132.045 806.556 134.912 807.205 137.312C807.473 138.157 807.906 138.94 808.479 139.616C809.051 140.293 809.752 140.849 810.541 141.253C811.33 141.657 812.191 141.902 813.074 141.971C813.957 142.041 814.846 141.936 815.688 141.66C817.258 140.887 818.734 139.938 820.088 138.832C821.544 137.862 823.383 137.146 825.022 137.757C827.182 138.563 827.949 141.208 828.144 143.505C828.474 145.796 828.952 148.063 829.576 150.292Z" fill="#5A3A1A"/>
<path id="Vector_53" opacity="0.2" d="M823.84 216.118L819.84 265.118L798.84 316.118L829.84 266.118L823.84 216.118Z" fill="black"/>
<path id="Vector_54" d="M793.93 335.11C795.076 334.19 796.006 333.029 796.656 331.71C797.305 330.391 797.657 328.946 797.687 327.476C797.718 326.007 797.425 324.548 796.831 323.203C796.237 321.859 795.355 320.661 794.247 319.694L807.626 286.558L789.423 290.234L779.51 321.137C778.009 323.105 777.282 325.555 777.467 328.023C777.652 330.492 778.736 332.806 780.513 334.529C782.291 336.251 784.638 337.261 787.111 337.369C789.584 337.476 792.01 336.672 793.93 335.11V335.11Z" fill="#FFB8B8"/>
<path id="Vector_55" d="M793.539 320.496L783.235 316.783C782.174 316.394 781.296 315.623 780.775 314.62C780.254 313.617 780.127 312.455 780.42 311.363L797.417 249.144L800.009 192.911C800.087 191.214 800.501 189.549 801.229 188.013C801.956 186.478 802.981 185.102 804.245 183.966C805.509 182.831 806.986 181.957 808.59 181.398C810.194 180.838 811.894 180.602 813.59 180.705C815.286 180.808 816.944 181.247 818.469 181.996C819.994 182.746 821.355 183.791 822.472 185.071C823.589 186.352 824.441 187.841 824.977 189.453C825.514 191.065 825.724 192.768 825.597 194.462L820.968 256.036L799.311 317.754C798.982 318.687 798.356 319.485 797.53 320.027C796.703 320.569 795.721 320.824 794.735 320.753C794.326 320.722 793.924 320.636 793.539 320.496V320.496Z" fill="#515151"/>
<path id="Vector_56" d="M908.916 528H738.916C738.651 528 738.396 527.895 738.209 527.707C738.021 527.52 737.916 527.265 737.916 527C737.916 526.735 738.021 526.48 738.209 526.293C738.396 526.105 738.651 526 738.916 526H908.916C909.181 526 909.436 526.105 909.623 526.293C909.811 526.48 909.916 526.735 909.916 527C909.916 527.265 909.811 527.52 909.623 527.707C909.436 527.895 909.181 528 908.916 528Z" fill="#CCCCCC"/>
</g>
<g id="phonegroup">
<path id="Vector_57" d="M792 78.5866H791.041V52.2455C791.041 50.2435 790.648 48.261 789.884 46.4113C789.12 44.5616 788.001 42.881 786.589 41.4653C785.178 40.0496 783.502 38.9267 781.658 38.1605C779.814 37.3944 777.837 37 775.841 37H720.2C718.204 37 716.228 37.3943 714.383 38.1605C712.539 38.9266 710.864 40.0496 709.452 41.4653C708.041 42.881 706.921 44.5616 706.157 46.4113C705.393 48.2609 705 50.2434 705 52.2454V196.755C705 200.798 706.601 204.676 709.452 207.535C712.303 210.394 716.169 212 720.2 212H775.841C777.837 212 779.814 211.606 781.658 210.84C783.502 210.073 785.178 208.95 786.589 207.535C788.001 206.119 789.12 204.438 789.884 202.589C790.648 200.739 791.041 198.757 791.041 196.755V97.3366H792V78.5866Z" fill="#3F3D56"/>
<path id="Vector_58" d="M788 52.3811V196.619C788 199.637 786.798 202.532 784.659 204.667C782.519 206.801 779.618 208 776.592 208H720.408C717.382 208 714.481 206.801 712.341 204.667C710.202 202.532 709 199.637 709 196.619V52.3811C709 49.3626 710.202 46.4678 712.341 44.3335C714.481 42.1991 717.382 41.0001 720.408 41H727.224C726.889 41.8211 726.761 42.7117 726.852 43.5935C726.943 44.4752 727.25 45.3212 727.746 46.0569C728.242 46.7926 728.911 47.3955 729.695 47.8126C730.479 48.2296 731.354 48.4481 732.242 48.4488H764.276C765.164 48.4481 766.039 48.2297 766.823 47.8126C767.607 47.3955 768.277 46.7926 768.772 46.0569C769.268 45.3212 769.575 44.4752 769.666 43.5934C769.757 42.7116 769.629 41.8211 769.294 41H776.592C779.618 41.0001 782.519 42.1991 784.659 44.3335C786.798 46.4678 788 49.3626 788 52.3811V52.3811Z" fill="white"/>
</g>
</g>
</g>
<defs>
<clipPath id="clip0_430_66">
<rect width="933.251" height="528" fill="white"/>
</clipPath>
</defs>
</svg>



</div>


<section id="homepushdown">
<div id="limitwidth">
<Plx parallaxData={ parallaxData }>
<h3 id="gradienttext" className="center bold">Who are we?</h3>
</Plx>
</div>
<Fade bottom>
<p className="center" id="homep">Product Lab is Harvard&apos;s first product management organization. Through training programs, real-world product experience, and industry mentorship, we introduce product management to problem-solvers from all backgrounds and help them grow into future product leaders.</p>
<div id="centereddiv"><Button id="button" href="/getinvolved">Get involved</Button></div>
</Fade>
</section>

<section id="homesection">
<div id="limitwidth">
<Plx parallaxData={ parallaxData2 }>
<h3 className="center bold" id="gradienttext">For companies</h3>
</Plx>
</div>
<Fade bottom>
<p className="center" id="homep">Each spring, Product Lab collaborates with a limited number of corporate partners to complete product-focused projects over the course of the semester. Sometimes, projects entail Product Lab designing and documenting entire products and features from scratch. Other times, Product Lab conducts user research and usability testing to help improve existing products.</p>
<p className="center" id="homep">As a corporate partner, you will be given a team of 4-8 Product Lab team members. All teams contain several APMs and one Team Lead. All Product Lab team members have successfully completed a 12-week PM bootcamp led by industry leaders, and many have prior PM internship experience.</p>
<div id="centereddiv"><Button id="button" href="/clients">Learn more</Button></div>
</Fade>
</section>

<section id="homesection">
<div id="limitwidth">
<Plx parallaxData={ parallaxData3 }>
<h3 className="center bold" id="gradienttext">For students</h3>
</Plx>
</div>
<Fade bottom>
<p className="center" id="homep">Our mission is to increase access to product education and help students from all backgrounds break into PM.</p>
<p className="center" id="homep">As a Product Lab member, you&apos;ll gain industry experience, receive exclusive career resources, build a portfolio of product projects, and access a growing community of peers, mentors, and employers.</p>
</Fade>
<div id="centereddiv"><Button id="button" href="/students">Learn more</Button></div>
</section>

<div id="divmesomespacesmall"></div>

<section>
  <h1 className="center bold">Sponsors and Past Clients</h1>
  <div id="divmesomespacesmall"></div>
  <Row xs={1} sm={1} md={2} lg={2} xl={4} className="g-5 align-items-center">

  <Col>
  <ExternalLink href={rocketblocks}><div id="sponsor"><Image src="/rocketblocks.svg" alt="Product Lab Board Photo" id="rocketblocks"></Image></div>
  <p className="center bold" style={{ paddingTop: 10 }}>Product management interview prep</p></ExternalLink>
  </Col>

  <Col>
    <div id="sponsor"><Image src="/grammarly.png" alt="Product Lab Board Photo" id="rocketblocks"></Image></div>
  </Col>

  <Col>
    <div id="sponsor"><Image src="/cisco.png" alt="Product Lab Board Photo" id="rocketblocks"></Image></div>
  </Col>

  <Col>
      <h2 id="sponsor" className="center bold">And many more...</h2>
  </Col>

  </Row>
</section>

<div id="divmesomespace"></div>

<section>
  <h1 className="center bold">Our alumni work at places like...</h1>
  <div id="divmesomespacesmall"></div>
  <Row xs={1} sm={1} md={2} lg={2} xl={4} className="g-5 align-items-center">

  <Col>
    <div id="sponsor"><Image src="/microsoft.svg" alt="microsoft logo" id="rocketblocks"></Image></div>
  </Col>

  <Col>
    <div id="sponsor"><Image src="/nasa.png" alt="nasa logo" id="rocketblocks"></Image></div>
  </Col>

  <Col>
    <div id="sponsor"><Image src="/accenture.png" alt="accenture logo" id="rocketblocks"></Image></div>
  </Col>

  <Col>
    <div id="sponsor"><Image src="/sequoia.png" alt="sequoia logo" id="rocketblocks"></Image></div>
  </Col>

  <Col>
    <div id="sponsor"><Image src="/atlassian.png" alt="atlassian logo" id="rocketblocks"></Image></div>
  </Col>

  <Col>
    <div id="sponsor"><Image src="/apple.png" alt="apple logo" id="rocketblocks"></Image></div>
  </Col>

  <Col>
    <div id="sponsor"><Image src="/salesforce.png" alt="salesforce logo" id="rocketblocks"></Image></div>
  </Col>

  <Col>
    <div id="sponsor"><Image src="/goldman.png" alt="goldman sachs logo" id="rocketblocks"></Image></div>
  </Col>

  </Row>
</section>
{/* 
<section id="homesection">
<Fade bottom>
<h1 className="center bold">Featured Blog Posts</h1>
</Fade>
<div id="divmesomespacesmall" />
<Container fluid>
<Row xs={1} sm={1} md={2} lg={3} xl={4} className="g-5 align-items-center">
{data.posts.map(post => (
    <Col key={post.id} id="padblog">
    <div id="centereddiv2">
    <Tilt style={{ height: 250, width: 250 }}>
    <Link className="App-link" to={`/blog/post/${post.slug}`}>
    <Card style={{"height" : '120%', width: '18rem' }} id="shadowy">
    <Card.Img variant="top" src={post.feature_image} />
    <Card.Body>
    <Card.Title>
        <p id="blogtitlefont">{post.title}</p>
    </Card.Title>
    <Card.Text>
    <a id="cardtextfont">Posted: <Moment format="MMM DD, YYYY">{post.updated_at}</Moment></a>
    </Card.Text>
    </Card.Body>
    </Card>
    </Link>
    </Tilt>
    </div>
    </Col>
    ))}
</Row>
</Container>
</section> */}
</div>
    )
}

export default Home;
