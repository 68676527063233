import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navb from './Nav';

function App() {

  return (
    <div className="App">
        <Navb />
    </div>
  );
}

export default App;
