import React from 'react';
import './App.css';
import { Row, Col, Container, Image } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Tilt from 'react-parallax-tilt';
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';
import Plx from 'react-plx';
import Roll from 'react-reveal/Roll';

  const Students = () => {

    const parallaxData = [
      {
        start: 'self',
        end: 1600,
        properties: [
          {
            startValue: 0,
            endValue: 25,
            property: 'translateX',
            unit: 'vw',
          },
        ],
      },
    ];
    const parallaxData2 = [
      {
        start: 'self',
        end: 2400,
        properties: [
          {
            startValue: 0,
            endValue: -25,
            property: 'translateX',
            unit: 'vw',
          },
        ],
      },
    ];
  
        return (
        <div id="studentcontainer">
<div id="divmesomespacesmall"></div>
<Roll top>
<h1 className="center bold" id="gradienttext">Students</h1>
</Roll>

<div id="studentanimation">
<svg id="studentgraphic" width="733" height="639" viewBox="0 0 733 639" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_472_156)">
<g id="leftguy">
<path id="Vector" d="M20.4322 622.437H37.4372L45.5272 556.846L20.4292 556.847L20.4322 622.437Z" fill="#9F616A"/>
<path id="Vector_2" d="M16.0947 616.885L49.5837 616.884H49.5851C55.2451 616.884 60.6733 619.133 64.6756 623.135C68.6779 627.137 70.9265 632.565 70.9269 638.226V638.919L16.0957 638.921L16.0947 616.885Z" fill="#2F2E41"/>
<path id="Vector_3" d="M9.74133 388.163C9.74133 388.163 2.37148 396.493 14.5748 428.968L12.4253 607.031L51.9725 606.259L60.6654 519.401L76.4925 392.651L9.74133 388.163Z" fill="#2F2E41"/>
<path id="Vector_4" d="M90.0562 412.781L89.6233 412.715C39.0315 404.999 0.853988 397.602 0.474258 397.528L0 397.436L13.2623 365.179C13.1575 361.808 10.2867 267.156 15.7823 239.961C21.1025 213.635 46.1799 209.321 49.0375 208.915L51.907 199.408L88.1584 218.271L94.4809 240.031L95.7652 299.67L90.0562 412.781Z" fill="#3F3D56"/>
<path id="Vector_5" d="M111.89 290.031C110.258 290.031 108.643 290.368 107.147 291.022C105.651 291.676 104.307 292.633 103.199 293.832H42.2666L19.9473 298.583L23.6118 326.847L103.008 309.772C104.322 311.256 105.988 312.388 107.852 313.063C109.717 313.738 111.72 313.936 113.681 313.638C115.641 313.34 117.495 312.555 119.074 311.356C120.654 310.157 121.907 308.581 122.721 306.773C123.535 304.965 123.882 302.981 123.732 301.004C123.582 299.027 122.939 297.119 121.862 295.454C120.785 293.789 119.308 292.421 117.567 291.473C115.825 290.526 113.873 290.03 111.89 290.031H111.89Z" fill="#9F616A"/>
<path id="Vector_6" d="M112.419 188.723C120.198 174.492 114.968 156.649 100.737 148.87C86.506 141.09 68.663 146.321 60.8837 160.552C53.1043 174.783 58.3344 192.626 72.5654 200.405C86.7965 208.184 104.639 202.954 112.419 188.723Z" fill="#9F616A"/>
<path id="Vector_7" d="M59.0314 151.161L57.6078 148.295L64.7713 144.736C64.7713 144.736 72.6737 131.878 86.9736 135.491C101.273 139.105 107.707 141.267 107.707 141.267L114.853 144.863L111.266 148.431L117.7 150.593L113.402 152.729L118.403 155.603L115.711 170.339C115.711 170.339 111.239 159.162 102.643 163.433C94.0469 167.704 78.3233 161.226 78.3233 161.226L64.6629 187.663C64.6629 187.663 61.8447 178.395 56.8202 180.866C56.8201 180.866 43.9836 160.423 59.0314 151.161Z" fill="#2F2E41"/>
<path id="Vector_8" d="M24.0014 332.145L17.0332 311.418L15.3477 242.324L18.3167 232.521C19.3853 228.992 21.1553 225.716 23.5205 222.888C25.8857 220.06 28.7975 217.739 32.0814 216.064C35.3652 214.388 38.9535 213.393 42.6313 213.137C46.3091 212.882 50.0005 213.371 53.4845 214.577C56.9685 215.782 60.1733 217.678 62.9069 220.152C65.6404 222.626 67.8465 225.626 69.3927 228.972C70.939 232.319 71.7938 235.943 71.9057 239.628C72.0177 243.313 71.3846 246.983 70.0444 250.417L54.1308 291.196L54.9397 292.766L89.4165 292.487L101.663 314.531L24.0014 332.145Z" fill="#3F3D56"/>
</g>
<g id="rightgirl">
<path id="Vector_9" d="M532.232 339.02C533.667 339.309 535.147 339.279 536.569 338.934C537.991 338.588 539.32 337.935 540.462 337.02C541.604 336.106 542.532 334.952 543.18 333.639C543.828 332.327 544.18 330.889 544.212 329.426L619.839 258.244L602.823 244.67L533.706 319.088C531.245 319.197 528.909 320.205 527.142 321.922C525.374 323.638 524.297 325.943 524.116 328.4C523.934 330.857 524.66 333.295 526.156 335.252C527.652 337.21 529.814 338.551 532.232 339.02V339.02Z" fill="#9F616A"/>
<path id="Vector_10" d="M609.123 269.75L594.41 252.507C593.991 252.017 593.683 251.442 593.507 250.821C593.331 250.201 593.29 249.55 593.388 248.912C593.486 248.275 593.72 247.666 594.075 247.127C594.429 246.588 594.896 246.132 595.442 245.79L614.597 233.799C617.119 231.671 620.382 230.63 623.671 230.904C626.96 231.179 630.005 232.746 632.14 235.263C634.274 237.779 635.324 241.04 635.058 244.329C634.793 247.618 633.233 250.668 630.722 252.809L615.919 269.787C615.495 270.273 614.971 270.662 614.383 270.928C613.795 271.193 613.157 271.328 612.512 271.325C611.867 271.321 611.231 271.179 610.646 270.907C610.061 270.635 609.542 270.241 609.123 269.75V269.75Z" fill="#CBCBCB"/>
<path id="Vector_11" d="M569.558 620.997L551.067 620.996L542.27 549.675L569.561 549.676L569.558 620.997Z" fill="#9F616A"/>
<path id="Vector_12" d="M574.273 638.921L514.651 638.919V638.165C514.652 632.01 517.097 626.108 521.449 621.756C525.801 617.404 531.703 614.959 537.858 614.958H537.859L574.274 614.96L574.273 638.921Z" fill="#2F2E41"/>
<path id="Vector_13" d="M717.365 620.997L698.875 620.996L690.078 549.675L717.369 549.676L717.365 620.997Z" fill="#9F616A"/>
<path id="Vector_14" d="M722.081 638.921L662.459 638.919V638.165C662.459 632.01 664.905 626.108 669.257 621.756C673.609 617.404 679.511 614.959 685.666 614.958H685.667L722.082 614.96L722.081 638.921Z" fill="#2F2E41"/>
<path id="Vector_15" d="M667.628 307.557L686.267 238.694L662.087 225.102H628.336L615.209 239.147C615.209 239.147 593.277 254.406 598.585 271.313L607.149 309.822L594.051 370.983L528.061 462.498L512.439 552.391L573.931 568.511L640.426 437.581L672.665 582.556L732.643 579.539L711.976 403.757C711.976 403.757 706.084 350.208 675.202 316.772L667.628 307.557Z" fill="#2F2E41"/>
<path id="Vector_16" d="M706.12 368.486C707.16 367.456 707.961 366.211 708.467 364.838C708.973 363.465 709.172 361.997 709.05 360.539C708.927 359.081 708.487 357.667 707.759 356.398C707.031 355.128 706.034 354.034 704.838 353.191L690.376 255.627L669.619 262.182L690.397 356.146C689.115 358.25 688.65 360.751 689.088 363.175C689.527 365.6 690.84 367.779 692.778 369.3C694.716 370.821 697.145 371.578 699.604 371.428C702.063 371.278 704.381 370.231 706.12 368.486V368.486Z" fill="#9F616A"/>
<path id="Vector_17" d="M693.95 270.939L671.432 273.536C670.792 273.61 670.142 273.545 669.529 273.345C668.916 273.145 668.353 272.816 667.878 272.379C667.404 271.942 667.029 271.408 666.78 270.814C666.53 270.219 666.412 269.577 666.433 268.933L667.162 246.346C666.803 243.066 667.759 239.778 669.821 237.201C671.882 234.625 674.882 232.972 678.161 232.603C681.44 232.235 684.731 233.182 687.313 235.236C689.895 237.291 691.557 240.285 691.934 243.563L697.771 265.319C697.938 265.942 697.969 266.594 697.861 267.23C697.753 267.866 697.51 268.471 697.147 269.004C696.785 269.538 696.311 269.987 695.76 270.321C695.208 270.654 694.591 270.866 693.95 270.939V270.939Z" fill="#CBCBCB"/>
<path id="Vector_18" d="M607.468 205.519V176.504C607.468 166.192 611.564 156.303 618.855 149.012C626.147 141.72 636.036 137.624 646.348 137.624C656.659 137.624 666.548 141.72 673.84 149.012C681.131 156.303 685.228 166.192 685.228 176.504V205.519C685.226 206.904 684.675 208.231 683.696 209.21C682.717 210.189 681.39 210.74 680.005 210.742H612.69C611.306 210.74 609.978 210.189 608.999 209.21C608.02 208.231 607.469 206.904 607.468 205.519V205.519Z" fill="#2F2E41"/>
<path id="Vector_19" d="M652.031 206.573C665.845 199.022 670.921 181.702 663.37 167.888C655.819 154.074 638.499 148.997 624.685 156.549C610.871 164.1 605.794 181.42 613.346 195.234C620.897 209.047 638.217 214.124 652.031 206.573Z" fill="#9F616A"/>
<path id="Vector_20" d="M598.27 178.825C598.279 170.671 601.522 162.854 607.288 157.088C613.054 151.322 620.871 148.079 629.025 148.07H634.828C642.983 148.079 650.8 151.322 656.566 157.088C662.332 162.854 665.575 170.671 665.584 178.825V179.406H653.32L649.138 167.693L648.301 179.406H641.963L639.853 173.496L639.431 179.406H598.27V178.825Z" fill="#2F2E41"/>
<path id="Vector_21" d="M635.553 214.004C634.977 213.225 634.633 212.298 634.561 211.332C634.488 210.365 634.69 209.398 635.144 208.541C641.293 196.845 649.903 175.232 638.475 161.903L637.653 160.946H670.832V210.758L640.692 216.076C640.387 216.13 640.077 216.157 639.767 216.158C638.945 216.158 638.134 215.962 637.401 215.588C636.668 215.214 636.035 214.671 635.553 214.004V214.004Z" fill="#2F2E41"/>
</g>
<g id="leftgirl">
<path id="Vector_22" d="M330.599 240.529C330.179 240.962 329.793 241.427 329.445 241.92L275.386 240.597L269.079 229.073L250.443 236.318L259.625 257.804C260.367 259.54 261.652 260.988 263.287 261.931C264.922 262.875 266.819 263.263 268.693 263.036L329.77 255.658C331.21 257.482 333.172 258.824 335.394 259.506C337.617 260.187 339.994 260.176 342.209 259.474C344.425 258.772 346.374 257.412 347.798 255.575C349.222 253.738 350.053 251.511 350.18 249.19C350.307 246.869 349.725 244.564 348.511 242.583C347.296 240.601 345.507 239.036 343.381 238.096C341.256 237.155 338.894 236.885 336.611 237.319C334.328 237.754 332.231 238.874 330.599 240.529H330.599Z" fill="#FFB7B7"/>
<path id="Vector_23" d="M154.374 568.836H172.149L180.605 500.275L154.371 500.276L154.374 568.836Z" fill="#FFB7B7"/>
<path id="Vector_24" d="M149.84 563.033L184.845 563.032H184.847C190.763 563.032 196.437 565.382 200.621 569.566C204.804 573.749 207.155 579.423 207.155 585.34V586.064L149.841 586.067L149.84 563.033Z" fill="#2F2E41"/>
<path id="Vector_25" d="M315.778 568.441L332.124 561.458L312.967 495.086L288.843 505.393L315.778 568.441Z" fill="#FFB7B7"/>
<path id="Vector_26" d="M309.329 564.885L341.52 551.133L341.521 551.132C346.962 548.808 353.103 548.741 358.594 550.945C364.084 553.148 368.475 557.443 370.799 562.884L371.084 563.55L318.378 586.067L309.329 564.885Z" fill="#2F2E41"/>
<path id="Vector_27" d="M240.773 255.381L242.058 276.21L204.487 280.231L165.173 284.438L176.744 252.81L240.773 255.381Z" fill="#FFB7B7"/>
<path id="Vector_28" d="M242.093 262.855C241.667 262.688 199.23 246.18 173.163 259.678L172.522 260.01L167.322 234.414L164.359 179.385L183.64 170.274L188.949 158.383L225.108 156.243L232.539 166.433L257.837 174.725L242.717 263.102L242.093 262.855Z" fill="#FFD600"/>
<path id="Vector_29" d="M243.422 213.183L247.894 178.041L258.078 174.941L258.25 175.005C258.485 175.091 264.02 177.209 265.535 184.872C266.989 192.227 277.941 237.995 278.052 238.456L278.161 238.914L252.428 249.21L243.422 213.183Z" fill="#FFD600"/>
<path id="Vector_30" d="M302.539 556.391L336.794 549.365L282.045 398.293L263.307 346.764C263.307 346.764 251.423 305.449 246.053 289.461L242.813 268.008L166.106 276.498C166.106 276.498 144.682 307.962 151.19 359.245L148.247 552L182.501 553.756L211.193 359.354L261.551 465.924C261.551 465.924 277.423 511.401 288.779 521.258L302.539 556.391Z" fill="#2F2E41"/>
<path id="Vector_31" d="M196.867 283.653C196.264 283.638 195.66 283.672 195.062 283.752L159.277 243.211L163.44 230.751L145.457 222.011L135.944 243.353C135.175 245.077 134.987 247.004 135.406 248.844C135.826 250.685 136.832 252.339 138.272 253.56L185.209 293.331C184.851 295.627 185.201 297.978 186.212 300.071C187.223 302.164 188.847 303.9 190.868 305.047C192.89 306.195 195.212 306.699 197.528 306.495C199.843 306.29 202.041 305.385 203.829 303.9C205.618 302.415 206.912 300.421 207.539 298.183C208.167 295.945 208.098 293.569 207.342 291.371C206.586 289.173 205.179 287.258 203.308 285.879C201.437 284.5 199.19 283.724 196.867 283.653V283.653Z" fill="#FFB7B7"/>
<path id="Vector_32" d="M142.217 228.017L156.207 188.169C157.779 183.471 159.749 180.617 162.063 179.686C162.518 179.481 163.012 179.373 163.512 179.368C164.011 179.363 164.507 179.463 164.966 179.659L171.239 187.584L177.061 212.043L162.134 247.157L142.217 228.017Z" fill="#FFD600"/>
<path id="Vector_33" d="M234.817 147.997H173.401C172.138 147.995 170.927 147.493 170.033 146.6C169.14 145.706 168.638 144.495 168.636 143.232V116.759C168.636 107.351 172.374 98.3285 179.026 91.6761C185.679 85.0236 194.701 81.2863 204.109 81.2863C213.517 81.2863 222.54 85.0236 229.192 91.6761C235.845 98.3285 239.582 107.351 239.582 116.759V143.232C239.581 144.495 239.078 145.706 238.185 146.6C237.292 147.493 236.081 147.995 234.817 147.997V147.997Z" fill="#2F2E41"/>
<path id="Vector_34" d="M211.399 147.38C225.762 147.38 237.406 135.736 237.406 121.373C237.406 107.009 225.762 95.3653 211.399 95.3653C197.035 95.3653 185.391 107.009 185.391 121.373C185.391 135.736 197.035 147.38 211.399 147.38Z" fill="#FFB7B7"/>
<path id="Vector_35" d="M247.974 119.407H210.42L210.035 114.015L208.109 119.407H202.327L201.563 108.72L197.747 119.407H186.558V118.877C186.566 111.438 189.525 104.305 194.786 99.0444C200.047 93.7839 207.179 90.8248 214.619 90.8163H219.913C227.353 90.8247 234.485 93.7838 239.746 99.0444C245.007 104.305 247.966 111.437 247.974 118.877L247.974 119.407Z" fill="#2F2E41"/>
<path id="Vector_36" d="M210.113 152.938C209.83 152.938 209.548 152.913 209.27 152.864L181.771 148.012V102.564H212.042L211.292 103.438C200.865 115.599 208.721 135.318 214.331 145.989C214.745 146.771 214.929 147.653 214.863 148.535C214.797 149.417 214.484 150.262 213.958 150.974C213.518 151.582 212.94 152.077 212.272 152.419C211.603 152.76 210.863 152.938 210.113 152.938V152.938Z" fill="#2F2E41"/>
</g>
<path id="Vector_37" d="M632.188 50C645.995 50 657.188 38.8071 657.188 25C657.188 11.1929 645.995 0 632.188 0C618.38 0 607.188 11.1929 607.188 25C607.188 38.8071 618.38 50 632.188 50Z" fill="#FFD600"/>
<path id="Vector_38" d="M93 75C106.807 75 118 63.8071 118 50C118 36.1929 106.807 25 93 25C79.1929 25 68 36.1929 68 50C68 63.8071 79.1929 75 93 75Z" fill="#FFD600"/>
<g id="rightguy">
<path id="Vector_39" d="M368.948 323.628C370.235 322.761 371.316 321.623 372.115 320.294C372.914 318.964 373.412 317.475 373.574 315.933C373.735 314.39 373.557 312.83 373.051 311.364C372.544 309.897 371.723 308.56 370.643 307.445L387.638 273.783L368.173 276.041L355.019 307.661C353.267 309.598 352.286 312.11 352.263 314.722C352.239 317.334 353.175 319.863 354.892 321.832C356.609 323.8 358.988 325.07 361.578 325.401C364.169 325.732 366.791 325.101 368.948 323.628V323.628Z" fill="#FFB7B7"/>
<path id="Vector_40" d="M505.24 562.075L489.924 562.074L482.638 502.999L505.242 503.001L505.24 562.075Z" fill="#FFB7B7"/>
<path id="Vector_41" d="M509.146 576.921L459.762 576.919V576.294C459.762 571.197 461.787 566.308 465.392 562.703C468.997 559.098 473.886 557.073 478.983 557.073H478.984L509.146 557.074L509.146 576.921Z" fill="#2F2E41"/>
<path id="Vector_42" d="M421.54 562.075L406.224 562.074L398.938 502.999L421.543 503.001L421.54 562.075Z" fill="#FFB7B7"/>
<path id="Vector_43" d="M421.698 576.921L372.314 576.919V576.294C372.315 571.197 374.34 566.308 377.945 562.703C381.549 559.098 386.438 557.073 391.536 557.073H391.537L421.699 557.074L421.698 576.921Z" fill="#2F2E41"/>
<path id="Vector_44" d="M420.285 312.572L405.294 347.108L392.928 545.614L424.159 544.365L432.689 449.736L459.002 365.454L474.129 459.415L480.376 541.866L506.218 542.985L516.516 312.572H420.285Z" fill="#2F2E41"/>
<path id="Vector_45" d="M495.22 142.155L451.771 143.241L426.263 156.337L424.863 252.951L408.953 319.654C408.953 319.654 508.357 326.496 522.099 316.502L505.859 240.298L527.64 157.943L495.22 142.155Z" fill="#F8B952"/>
<path id="Vector_46" d="M432.972 157.938L428.927 155.206C428.927 155.206 412.17 160.229 407.217 182.573C407.217 182.573 403.427 193.783 401.764 201.49C399.857 210.33 357.738 291.532 357.738 291.532L382.955 298.766L429.187 224L432.972 157.938Z" fill="#F8B952"/>
<path id="Vector_47" d="M526.96 336.017C525.962 334.829 525.237 333.437 524.835 331.939C524.434 330.44 524.366 328.872 524.636 327.344C524.906 325.817 525.508 324.367 526.399 323.097C527.29 321.827 528.448 320.768 529.793 319.994L522.74 282.951L540.828 290.489L544.752 324.51C545.902 326.855 546.152 329.541 545.454 332.058C544.757 334.574 543.16 336.748 540.967 338.166C538.774 339.585 536.137 340.149 533.555 339.753C530.973 339.357 528.627 338.028 526.96 336.017V336.017Z" fill="#FFB7B7"/>
<path id="Vector_48" d="M521.103 159.096L525.744 157.586C525.744 157.586 540.466 167.034 539.065 189.878C539.065 189.878 539.617 201.698 539.09 209.565C538.486 218.589 546.586 308.256 546.586 308.256H520.352L509.108 227.055L521.103 159.096Z" fill="#F8B952"/>
<path id="Vector_49" d="M505.886 101.621C505.886 107.578 504.12 113.401 500.81 118.354C497.501 123.307 492.797 127.168 487.293 129.448C481.79 131.727 475.733 132.324 469.891 131.162C464.048 129.999 458.682 127.131 454.469 122.919C450.257 118.706 447.388 113.339 446.226 107.497C445.064 101.654 445.66 95.5983 447.94 90.0947C450.22 84.5911 454.08 79.8871 459.033 76.5775C463.987 73.2679 469.81 71.5014 475.767 71.5014V71.5014C483.735 71.4815 491.385 74.6278 497.033 80.2482C502.682 85.8685 505.866 93.5025 505.886 101.471C505.886 101.521 505.886 101.571 505.886 101.621Z" fill="#FFB7B7"/>
<path id="Vector_50" d="M445.748 61.165C449.276 53.5779 456.104 55.078 462.075 57.6498C469.638 55.9724 476.83 50.9505 484.977 53.9204C493.003 65.5936 519.95 62.1591 514.217 81.1631C514.209 85.7168 522.785 83.0662 521.288 90.5242C525.831 104.877 504.889 132.059 492.867 126.335C495.84 120.886 502.633 108.509 492.331 107.281C470.17 127.901 490.044 68.0168 462.2 85.432C452.981 93.8693 440.359 69.6044 445.748 61.165Z" fill="#2F2E41"/>
</g>
<path id="Vector_51" d="M346.188 113C356.129 113 364.188 104.941 364.188 95C364.188 85.0589 356.129 77 346.188 77C336.246 77 328.188 85.0589 328.188 95C328.188 104.941 336.246 113 346.188 113Z" fill="#FFD600"/>
</g>
<defs>
<clipPath id="clip0_472_156">
<rect width="732.643" height="638.921" fill="white"/>
</clipPath>
</defs>
</svg>
</div>

    <div id="divmesomespace"></div>
    <h1 className="center bold">Our initiatives</h1>
    <div id="divmesomespacesmall"></div>
    
    <Container fluid>
      <Row xs={1} sm={1} md={2} lg={2} xl={2} className="g-5 align-items-center">

        <Col>
        <div id="limitwidth">
        <Plx parallaxData={ parallaxData }>
        <h2 className="bold" id="gradienttextsub">Fall Semester</h2>
        </Plx>
        </div>
        <Fade bottom>
        <div id="studentsitemtext">
        <li><span className="bold">Product Fellowship:</span> Series of product management workshops, speaker events, and a final project</li>
        <ul>
          <li>Led by industry professionals, alumni, and students</li>
          <li>Topics range from breaking into PM to design thinking, user research, and product roadmapping</li>
          <li>At the end of fellowship, students are divided into teams to complete a product case study project. Members often find that the product case study becomes a valuable portfolio project</li>
          <li>Students become Product Lab members upon successful completion of fellowship</li>
        </ul>
        </div>
        </Fade>
        </Col>
        
        <Fade bottom>
        <Col>
          <div id="centereddiv"><Tilt><Image src="/boardphoto.JPG" alt="Product Lab Board Photo" id="studentspics"></Image></Tilt></div>
        </Col>
        </Fade>
          
        <Col>
        <div id="limitwidth">
        <Plx parallaxData={ parallaxData2 }>
        <h2 className="bold right" id="gradienttextsub">Spring Semester</h2>
        </Plx>
        </div>
        <Fade bottom>
        <div id="studentsitemtext">
          <ul>
            <li><span className="bold">Product Consulting Projects:</span> There is a competitive application process to join Product Lab case teams, consisting of a resume drop, a first round interview with deliverable, and a final behavioral interview. 
            Prior commitment to the fellowship will be taken into consideration and increase the likelihood of case team acceptance. Those accepted into case teams will first join as Associate Product Managers, with the potential to work as a Senior Product Manager or Cast Team Lead in future semesters. Case teams are paired with a company to gain real-world product experience.</li>
          </ul>
        </div>
        </Fade>
        </Col>
        
        <Fade bottom>
        <Col>
        <div id="centereddiv"><Tilt><Image src="/teamleadmeeting.JPG" alt="Product Lab Case Team Lead Meeting" id="studentspics"></Image></Tilt></div>
        </Col>
        </Fade>

        <Col>
        <div id="studentsitemtext">
        <h2 className="bold" id="gradienttextsub">All the time</h2>
        <Fade bottom>
        <ul>
          <li>Socials</li>
          <li>Speaker events</li>
          <li>Networking opportunities</li>
          <li>Interview prep and career resources</li>
        </ul>
        </Fade>
        </div>
        </Col>
        
        <Fade bottom>
        <Col>
        <div id="centereddiv"><Tilt><Image src="/cookiesocial.jpg" alt="Insomnia Cookie Social" id="studentspics"></Image></Tilt></div>
        </Col>
        </Fade>

      </Row>
    </Container>
    <div id="divmesomespace"></div>
    <Fade bottom>
    <h1 className="bold" id="center">Our Values: What Product Lab is About</h1>
    </Fade>
    <div id="divmesomespacesmall"></div>
    <Container fluid>
      <Row xs={1} sm={1} md={1} lg={1} xl={1} className="g-5 align-items-center">
      <Fade bottom cascade>

        <Col>
        <div id="centereddiv"><Tilt>
        <svg id="simpleshadow" width="167" height="167" viewBox="0 0 167 167" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M73.4141 13.4625C73.441 13.193 73.3331 12.9324 73.1356 12.7528C72.9379 12.5731 72.6595 12.4923 72.3989 12.5372L68.5811 13.193C68.3296 13.2379 68.1139 13.3816 67.9883 13.5972C67.8624 13.8128 67.8355 14.0733 67.9075 14.3158C70.9617 24.6555 72.9559 29.3985 72.9649 29.3446C73.4859 24.5745 73.4141 13.5523 73.4141 13.4625Z" fill="#975500"/>
        <path d="M50.0486 27.2962C49.9588 27.0715 49.7791 26.901 49.5544 26.811C49.33 26.7302 49.0785 26.7392 48.8629 26.8471L45.3953 28.5808C45.1168 28.7244 44.9373 29.0029 44.9192 29.3083C44.9012 29.6229 45.0899 29.8652 45.3235 30.0809C51.9799 36.3331 53.0579 39.2256 53.1297 39.2076C53.5161 39.1089 53.3902 35.4259 50.0486 27.2962Z" fill="#975500"/>
        <path d="M93.5803 13.4625C93.5533 13.193 93.661 12.9324 93.8587 12.7528C94.0564 12.5731 94.3349 12.4923 94.5954 12.5372L98.413 13.193C98.6646 13.2379 98.8802 13.3816 99.0061 13.5972C99.1318 13.8128 99.1587 14.0733 99.0869 14.3158C96.0327 24.6555 94.0385 29.3985 94.0293 29.3446C93.5174 24.5745 93.5892 13.5523 93.5803 13.4625Z" fill="#975500"/>
        <path d="M116.954 27.2962C117.044 27.0715 117.224 26.901 117.448 26.811C117.673 26.7302 117.925 26.7392 118.14 26.8471L121.608 28.5808C121.886 28.7244 122.066 29.0029 122.084 29.3083C122.102 29.6229 121.913 29.8652 121.679 30.0809C115.023 36.3331 113.945 39.2256 113.873 39.2076C113.487 39.1089 113.604 35.4259 116.954 27.2962Z" fill="#975500"/>
        <path d="M111.888 122.642C116.298 126.702 115.391 146.016 115.005 150.759C114.52 156.705 144.433 154.684 143.886 150.489C143.499 147.516 144.451 124.816 144.451 123.729" fill="url(#paint0_linear_474_285)"/>
        <path d="M111.646 122.408C111.735 122.48 111.834 122.552 111.924 122.624L113.981 122.166C115.221 123.226 116.263 124.429 116.838 125.903C118.688 126.9 120.683 127.088 122.156 127.825C128.309 130.915 116.2 128.238 116.514 132.649C117.565 138.533 115.984 150.741 114.996 150.741C115.383 146.007 116.29 126.684 111.879 122.624C111.807 122.561 111.726 122.489 111.646 122.408Z" fill="url(#paint1_radial_474_285)"/>
        <path d="M143.886 150.481C144.057 151.819 141.129 152.933 137.077 153.642C139.745 150.301 139.422 131.266 138.487 128.993C137.706 127.098 139.305 123.468 141.335 120.783L144.452 123.72C144.452 124.807 143.491 147.507 143.886 150.481Z" fill="url(#paint2_linear_474_285)"/>
        <path d="M141.784 126.28C142.7 125.822 143.572 125.283 144.416 124.69C144.263 129.227 143.527 147.813 143.877 150.481C143.967 151.137 143.257 151.747 142 152.277C141.577 148.226 143.437 135.937 142.79 134.212C141.029 129.559 137.715 129.613 138.424 127.781C139.376 127.628 140.634 127.125 141.784 126.28Z" fill="url(#paint3_radial_474_285)"/>
        <path d="M143.886 150.481C143.499 147.507 144.452 124.807 144.452 123.72C138.613 131.392 123.422 129.164 116.191 125.553C115.176 125.041 114.34 124.735 113.631 124.564C116.02 127.825 115.329 146.789 115.005 150.759C114.511 156.706 144.434 154.676 143.886 150.481Z" fill="url(#paint4_linear_474_285)"/>
        <path d="M85.2349 89.9164C83.9413 86.0898 92.2326 81.0591 99.6616 88.1378C100.524 89.0271 101.369 90.0422 102.186 91.201C104.171 94.0398 106.866 96.3573 109.552 94.0396C112.238 91.722 112.247 83.3858 111.789 78.9303L121.356 77.8072C121.23 79.5859 121.176 83.251 121.814 83.2961C122.452 83.3409 122.973 81.8048 123.09 80.2867C123.197 78.7775 132.576 85.7213 133.142 85.9998C133.564 86.2065 136.313 85.1105 139.618 86.5478C140.723 87.0239 144.523 91.2818 144.802 91.2997C144.802 91.2997 144.541 85.4608 144.721 82.5953C144.9 79.7385 154.746 90.222 154.216 93.2941C153.309 98.5492 154.809 115.895 146.059 123.441C135.944 132.173 117.466 129.702 110.783 121.959C104.099 114.216 102.635 107.649 97.5687 100.723C93.2568 94.8842 87.1214 93.0246 85.2349 89.9164Z" fill="url(#paint5_linear_474_285)"/>
        <path d="M144.703 82.6235C144.883 79.7669 144.883 72.0415 145.359 67.8463C145.835 63.6512 147.901 61.6301 151.36 62.2948C154.818 62.9596 154.342 70.6579 154.342 72.5264C154.342 75.3832 154.728 90.2591 154.198 93.3313" fill="url(#paint6_linear_474_285)"/>
        <path d="M133.124 86.0184C134.633 86.1712 134.337 83.539 134.489 78.5983C134.606 74.8254 134.678 64.5309 134.912 59.0961C135.208 52.3679 136.502 50.5622 139.745 50.2747C142.978 49.9873 144.434 53.9577 144.353 58.3955C144.29 62.1413 143.931 68.0433 143.365 75.4274C143.023 79.8471 143.679 91.2375 144.784 91.3183" fill="url(#paint7_linear_474_285)"/>
        <path d="M111.781 78.9401C111.314 74.4755 111.484 58.8088 111.224 54.6138C110.963 50.4098 113.487 48.4246 116.209 48.2807C118.931 48.1371 120.916 50.0595 121.096 58.3957C121.213 63.6508 121.806 68.4477 121.348 77.8352L114.997 83.8987L111.781 78.9401Z" fill="url(#paint8_linear_474_285)"/>
        <path d="M123.081 80.3055C123.189 78.7965 123.027 64.0821 123.081 60.8571C123.189 54.1019 122.075 44.0318 128.138 43.448C132.037 43.0708 132.872 46.6909 133.043 48.281C133.223 49.8708 132.854 67.882 132.729 69.5169C132.612 71.1428 132.567 85.7403 133.133 86.0188" fill="url(#paint9_linear_474_285)"/>
        <path d="M119.47 49.924C120.44 51.4602 121.006 54.227 121.096 58.3861C121.213 63.6412 121.743 68.4383 121.347 77.8255C121.276 79.6041 121.168 83.2693 121.806 83.3142C118.293 80.9606 120.117 67.1716 120.207 60.7847C120.296 53.9934 118.742 48.7652 119.47 49.924Z" fill="url(#paint10_radial_474_285)"/>
        <path d="M132.729 69.5171C132.613 71.143 132.567 85.7405 133.134 86.019C133.134 86.019 130.843 83.4589 131.184 78.7336C131.894 69.0589 132.172 58.7285 131.588 53.2488C131.04 48.0835 131.984 45.0203 132.361 45.7929C132.747 46.5652 132.972 47.6793 133.035 48.2901C133.214 49.8802 132.846 67.8911 132.729 69.5171Z" fill="url(#paint11_radial_474_285)"/>
        <path d="M144.353 58.3867C144.29 62.1326 143.931 68.0345 143.365 75.4187C143.024 79.8383 143.679 91.2288 144.784 91.3095C139.017 91.3816 144.955 56.3475 141.838 50.787C143.616 51.9996 144.416 55.063 144.353 58.3867Z" fill="url(#paint12_radial_474_285)"/>
        <path d="M145.961 91.2914C145.538 91.4442 144.981 91.4352 144.793 91.3183C144.793 91.3183 144.55 85.2368 144.712 82.6137C144.891 79.7571 144.891 72.0317 145.368 67.8367C145.574 65.9951 146.086 64.5668 146.895 63.6055C146.419 65.6538 147.29 65.3573 146.634 70.3339C146.275 72.9928 148.718 90.3213 145.961 91.2914Z" fill="url(#paint13_linear_474_285)"/>
        <path d="M135.729 71.143C135.379 75.6975 136.6 85.5608 133.627 86.001C134.31 85.7226 134.381 82.4347 134.498 78.59C134.615 74.817 134.642 64.5136 134.92 59.0877C135.217 53.3296 135.9 51.7664 137.993 50.6615C134.354 56.7251 136.582 59.968 135.729 71.143Z" fill="url(#paint14_radial_474_285)"/>
        <path d="M124.671 63.4619C124.455 70.289 125.785 82.3174 122.003 83.2695C122.551 83.027 122.982 81.6526 123.081 80.3051C123.189 78.796 123.027 64.0817 123.081 60.8569C123.144 56.6706 122.623 50.0322 124.374 46.2594C123.44 51.0294 124.842 57.9283 124.671 63.4619Z" fill="url(#paint15_linear_474_285)"/>
        <path d="M111.529 89.8178C112.005 87.069 112.059 81.6342 111.78 78.9392C111.313 74.4747 111.484 58.8082 111.223 54.6132C111.142 53.3285 111.322 52.2595 111.69 51.3792C111.798 53.3285 112.723 55.5114 112.49 59.2844C112.122 65.3479 113.02 75.0316 113.325 78.3554C113.631 81.6791 115.005 90.0783 111.529 89.8178Z" fill="url(#paint16_radial_474_285)"/>
        <path d="M127.815 128.948C126.567 125.202 126.773 122.085 130.537 121.6C140.805 120.261 148.926 112.41 151.782 96.6266C154.324 82.5502 154.073 69.8841 151.764 62.4192C154.773 63.5959 154.333 70.7374 154.333 72.525C154.333 75.3817 154.72 90.2577 154.19 93.3298C153.282 98.5849 154.782 115.931 146.033 123.477C141.164 127.681 134.328 129.28 127.815 128.948Z" fill="url(#paint17_linear_474_285)"/>
        <path d="M153.525 80.6648C153.507 80.6107 153.498 80.5838 153.498 80.5838C150.417 101.847 148.351 113.588 137.364 126.532C135.819 128.356 139.107 128.257 141.784 126.281C143.311 125.517 144.748 124.592 146.042 123.469C154.782 115.923 153.291 98.577 154.198 93.3219C154.477 91.696 153.345 84.4106 153.525 80.6648Z" fill="url(#paint18_radial_474_285)"/>
        <path opacity="0.16" d="M104.611 103.59C103.048 105.476 108.303 113.417 112.301 116.732C116.29 120.056 120.799 121.223 122.371 119.337C123.934 117.451 120.386 112.734 117.287 108.566C111.933 101.371 106.175 101.703 104.611 103.59Z" fill="url(#paint19_radial_474_285)"/>
        <path opacity="0.16" d="M149.096 101.802C150.83 103.895 145.548 115.932 140.338 118.968C135.128 122.005 128.597 121.07 127.321 118.663C126.046 116.255 131.463 112.51 135.9 108.359C143.554 101.191 147.363 99.7086 149.096 101.802Z" fill="url(#paint20_radial_474_285)"/>
        <path d="M144.784 91.3183C144.784 91.3183 144.541 85.2368 144.703 82.6137C144.883 79.7571 144.883 72.0317 145.359 67.8367C145.565 65.9951 146.078 64.5668 146.886 63.6055C146.41 65.6538 145.655 69.6242 145.763 77.9065C146.149 92.4681 144.973 91.4352 144.784 91.3183Z" fill="url(#paint21_radial_474_285)"/>
        <path d="M85.2358 89.9168C85.0201 89.2701 85.0919 88.5872 85.3794 87.9315C86.3407 90.5727 89.2062 93.0789 93.9853 94.4802C99.6715 96.1421 105.025 103.077 107.774 110.174C110.523 117.279 119.434 122.669 125.417 121.645C131.4 120.621 145.629 126.541 150.426 101.837C150.426 101.828 150.426 101.828 150.426 101.819C150.606 100.185 150.866 98.6484 151.288 97.3009C154.226 87.9315 152.267 75.2026 152.052 69.8937C152.007 68.8516 151.27 65.42 153.561 64.5127C154.567 67.1267 154.342 71.214 154.342 72.5076C154.342 75.3642 154.729 90.2403 154.199 93.3125C154.127 93.7166 154.109 94.9385 154.064 95.4324C154.055 95.5672 154.037 95.6929 154.028 95.8367C154.028 95.8726 154.019 95.9085 154.019 95.9444C154.01 96.0344 154.01 96.1152 154.001 96.2049C154.001 96.2229 154.001 96.2498 154.001 96.268C153.777 99.7623 154.244 116.121 146.051 123.46C136.089 132.371 117.458 129.721 110.775 121.977C104.091 114.234 102.627 107.667 97.5606 100.741C93.2576 94.8844 87.1222 93.025 85.2358 89.9168Z" fill="url(#paint22_radial_474_285)"/>
        <path d="M99.6622 88.1478C100.525 89.0371 101.369 90.052 102.187 91.211C104.172 94.0496 106.867 96.3671 109.553 94.0496C110.613 93.1334 111.223 91.6062 111.538 89.8187C111.331 99.107 106.543 97.2656 103.39 94.0406C100.228 90.8156 97.7849 85.2911 90.8319 85.2462C89.5744 85.2372 88.3525 85.5067 87.4453 85.7134C90.239 84.0156 95.1079 83.8 99.6622 88.1478Z" fill="url(#paint23_radial_474_285)"/>
        <path d="M110.766 121.978C104.082 114.234 102.618 107.668 97.5516 100.742C93.2576 94.8848 87.1222 93.0163 85.2358 89.9172C85.0201 89.2703 85.0919 88.5877 85.3794 87.9318C86.3407 90.5729 90.0596 91.4173 93.9853 94.4805C99.6715 98.9093 105.025 105.125 107.774 112.231C110.523 119.337 119.434 122.678 125.417 121.654L110.766 121.978Z" fill="url(#paint24_linear_474_285)"/>
        <path d="M55.1153 122.642C50.7046 126.702 51.6121 146.016 51.9982 150.759C52.4834 156.705 22.5697 154.684 23.1177 150.489C23.5039 147.516 22.5518 124.816 22.5518 123.729" fill="url(#paint25_linear_474_285)"/>
        <path d="M55.358 122.408C55.2683 122.48 55.1695 122.552 55.0796 122.624L53.0226 122.166C51.7828 123.226 50.7409 124.429 50.1658 125.912C48.3154 126.909 46.3211 127.097 44.8479 127.834C38.6946 130.924 50.8037 128.247 50.4894 132.658C49.4383 138.542 51.0194 150.75 52.0074 150.75C51.6212 146.016 50.7138 126.693 55.1245 122.633C55.1965 122.561 55.2773 122.489 55.358 122.408Z" fill="url(#paint26_radial_474_285)"/>
        <path d="M23.1177 150.481C22.9471 151.819 25.8755 152.933 29.9269 153.642C27.2589 150.301 27.5824 131.266 28.5166 128.993C29.2981 127.098 27.6991 123.468 25.669 120.783L22.5518 123.72C22.5518 124.807 23.5131 147.507 23.1177 150.481Z" fill="url(#paint27_linear_474_285)"/>
        <path d="M25.2188 126.28C24.3026 125.822 23.4313 125.283 22.5869 124.69C22.7395 129.227 23.4762 147.813 23.1259 150.481C23.0361 151.137 23.7457 151.747 25.0034 152.277C25.4255 148.226 23.566 135.937 24.2129 134.212C25.9735 129.559 29.2882 129.613 28.5787 127.781C27.6263 127.628 26.3688 127.125 25.2188 126.28Z" fill="url(#paint28_radial_474_285)"/>
        <path d="M23.1167 150.481C23.5029 147.507 22.5508 124.807 22.5508 123.72C28.3897 131.392 43.58 129.164 50.8116 125.553C51.8265 125.041 52.6619 124.735 53.3717 124.564C50.9821 127.825 51.6739 146.789 51.9973 150.759C52.4914 156.706 22.5687 154.676 23.1167 150.481Z" fill="url(#paint29_linear_474_285)"/>
        <path d="M81.7674 89.9255C83.0608 86.0987 74.7694 81.0683 67.3404 88.147C66.4781 89.0363 65.6337 90.0514 64.8162 91.2102C62.831 94.0488 60.1361 96.3665 57.4501 94.0488C54.7641 91.7312 54.7551 83.395 55.2133 78.9394L45.6463 77.8164C45.7722 79.5951 45.826 83.2602 45.1883 83.305C44.5504 83.3499 44.0293 81.8139 43.9126 80.2957C43.8049 78.7866 34.4264 85.7305 33.8605 86.009C33.4384 86.2156 30.6896 85.1197 27.3838 86.5569C26.2789 87.0331 22.479 91.291 22.2005 91.3089C22.2005 91.3089 22.4611 85.47 22.2813 82.6045C22.1018 79.7477 12.2563 90.2312 12.7863 93.3033C13.6936 98.5584 12.1934 115.905 20.9428 123.45C31.0578 132.182 49.5359 129.712 56.2195 121.968C62.9028 114.225 64.367 107.658 69.4336 100.732C73.7455 94.8842 79.8809 93.0249 81.7674 89.9255Z" fill="url(#paint30_linear_474_285)"/>
        <path d="M22.3001 82.6235C22.1204 79.7669 22.1204 72.0415 21.6444 67.8463C21.1683 63.6512 19.1021 61.6301 15.6437 62.2948C12.1852 62.9596 12.6613 70.6579 12.6613 72.5264C12.6613 75.3832 12.275 90.2591 12.805 93.3313" fill="url(#paint31_linear_474_285)"/>
        <path d="M33.8787 86.0184C32.3696 86.1712 32.6661 83.539 32.5133 78.5983C32.3966 74.8254 32.3248 64.5309 32.0912 59.0961C31.7947 52.3679 30.5011 50.5622 27.2582 50.2747C24.0155 49.9873 22.569 53.9577 22.65 58.3955C22.7129 62.1413 23.0721 68.0433 23.638 75.4274C23.9793 79.8471 23.3237 91.2375 22.2188 91.3183" fill="url(#paint32_linear_474_285)"/>
        <path d="M55.2227 78.9401C55.6897 74.4755 55.5192 58.8088 55.7797 54.6138C56.0402 50.4098 53.516 48.4246 50.7941 48.2807C48.0722 48.1371 46.087 50.0595 45.9073 58.3957C45.7904 63.6508 45.1975 68.4477 45.6557 77.8352L52.0067 83.8987L55.2227 78.9401Z" fill="url(#paint33_linear_474_285)"/>
        <path d="M43.9223 80.3055C43.8143 78.7965 43.9761 64.0821 43.9223 60.8571C43.8143 54.1019 44.9282 44.0318 38.8647 43.448C34.9661 43.0708 34.1306 46.6909 33.9599 48.281C33.7893 49.8708 34.1486 67.882 34.2743 69.5169C34.3912 71.1428 34.436 85.7403 33.8701 86.0188" fill="url(#paint34_linear_474_285)"/>
        <path d="M47.533 49.924C46.5627 51.4602 45.9968 54.227 45.907 58.3861C45.7901 63.6412 45.2601 68.4383 45.6555 77.8255C45.7273 79.6041 45.835 83.2693 45.1973 83.3142C48.7097 80.9606 46.8861 67.1716 46.7963 60.7847C46.7065 53.9934 48.2605 48.7652 47.533 49.924Z" fill="url(#paint35_radial_474_285)"/>
        <path d="M34.2743 69.5171C34.3912 71.143 34.436 85.7405 33.8701 86.019C33.8701 86.019 36.1607 83.4589 35.8194 78.7336C35.1097 69.0589 34.8312 58.7285 35.4153 53.2488C35.9633 48.0835 35.0199 45.0203 34.6427 45.7929C34.2563 46.5652 34.0319 47.6793 33.9689 48.2901C33.7893 49.8802 34.1576 67.8911 34.2743 69.5171Z" fill="url(#paint36_radial_474_285)"/>
        <path d="M22.65 58.3867C22.7129 62.1326 23.0721 68.0345 23.638 75.4187C23.9793 79.8383 23.3237 91.2288 22.2188 91.3095C27.9859 91.3816 22.048 56.3475 25.1653 50.787C23.3865 51.9996 22.587 55.063 22.65 58.3867Z" fill="url(#paint37_radial_474_285)"/>
        <path d="M21.0424 91.2914C21.4645 91.4442 22.0216 91.4352 22.2101 91.3183C22.2101 91.3183 22.4527 85.2368 22.2909 82.6137C22.1114 79.7571 22.1114 72.0317 21.6352 67.8367C21.4286 65.9951 20.9165 64.5668 20.1081 63.6055C20.5842 65.6538 19.7129 65.3573 20.3686 70.3339C20.728 72.9928 18.2846 90.3213 21.0424 91.2914Z" fill="url(#paint38_linear_474_285)"/>
        <path d="M31.2742 71.143C31.6245 75.6975 30.4029 85.5608 33.3853 86.001C32.7025 85.7226 32.6307 82.4347 32.5138 78.59C32.3971 74.817 32.3702 64.5136 32.0917 59.0877C31.7953 53.3296 31.1125 51.7664 29.0195 50.6615C32.6487 56.7251 30.4209 59.968 31.2742 71.143Z" fill="url(#paint39_radial_474_285)"/>
        <path d="M42.3317 63.4619C42.5474 70.289 41.2178 82.3174 44.9998 83.2695C44.4518 83.027 44.0205 81.6526 43.9218 80.3051C43.8138 78.796 43.9756 64.0817 43.9218 60.8569C43.8587 56.6706 44.3798 50.0322 42.6281 46.2594C43.5623 51.0294 42.161 57.9283 42.3317 63.4619Z" fill="url(#paint40_linear_474_285)"/>
        <path d="M55.4648 89.8178C55.4738 89.8178 55.4738 89.8178 55.4648 89.8178C54.9887 87.069 54.9348 81.6342 55.2133 78.9392C55.6805 74.4747 55.5097 58.8082 55.7702 54.6132C55.851 53.3285 55.6715 52.2595 55.303 51.3792C55.1953 53.3285 54.2701 55.5114 54.5035 59.2844C54.872 65.3479 53.9737 75.0316 53.6681 78.3554C53.3717 81.6791 51.9973 90.0783 55.4648 89.8178Z" fill="url(#paint41_radial_474_285)"/>
        <path d="M39.1881 128.948C40.4368 125.202 40.2302 122.085 36.4662 121.6C26.1986 120.261 18.0779 112.41 15.2213 96.6266C12.6791 82.5502 12.9306 69.8841 15.2392 62.4192C12.2299 63.5959 12.6701 70.7374 12.6701 72.525C12.6701 75.3817 12.2838 90.2577 12.8138 93.3298C13.7211 98.5849 12.2209 115.931 20.9705 123.477C25.8393 127.681 32.6753 129.28 39.1881 128.948Z" fill="url(#paint42_linear_474_285)"/>
        <path d="M13.4778 80.6648C13.4958 80.6107 13.5048 80.5838 13.5048 80.5838C16.586 101.847 18.6521 113.588 29.6383 126.532C31.1835 128.356 27.8957 128.257 25.2187 126.281C23.6917 125.517 22.2542 124.592 20.9608 123.469C12.2202 115.923 13.7114 98.577 12.8041 93.3219C12.5256 91.696 13.6575 84.4106 13.4778 80.6648Z" fill="url(#paint43_radial_474_285)"/>
        <path opacity="0.16" d="M62.3909 103.59C63.954 105.476 58.699 113.417 54.7014 116.732C50.7038 120.047 46.2034 121.223 44.6313 119.337C43.0684 117.451 46.6167 112.734 49.7158 108.566C55.0696 101.371 60.8278 101.703 62.3909 103.59Z" fill="url(#paint44_radial_474_285)"/>
        <path opacity="0.16" d="M17.9074 101.802C16.1737 103.895 21.4556 115.932 26.6658 118.968C31.876 122.005 38.4068 121.07 39.6824 118.663C40.9579 116.255 35.5413 112.51 31.1036 108.359C23.45 101.191 19.6412 99.7086 17.9074 101.802Z" fill="url(#paint45_radial_474_285)"/>
        <path d="M22.2192 91.3183C22.2192 91.3183 22.4618 85.2368 22.3 82.6137C22.1205 79.7571 22.1205 72.0317 21.6443 67.8367C21.4377 65.9951 20.9256 64.5668 20.1172 63.6055C20.5933 65.6538 21.3479 69.6242 21.24 77.9065C20.8538 92.4681 22.0215 91.4352 22.2192 91.3183Z" fill="url(#paint46_radial_474_285)"/>
        <path d="M81.768 89.9259C81.9836 89.2792 81.9118 88.5964 81.6244 87.9407C80.6631 90.5818 77.7976 93.0881 73.0186 94.4894C67.3323 96.1513 61.9783 103.086 59.2295 110.183C56.4806 117.288 47.5695 122.678 41.5867 121.654C35.604 120.63 21.3749 126.55 16.5779 101.847C16.5779 101.838 16.5779 101.838 16.5779 101.829C16.3982 100.194 16.1377 98.6575 15.7155 97.3101C12.778 87.9407 14.7364 75.2118 14.952 69.9029C14.9969 68.8608 15.7335 65.4291 13.4428 64.5219C12.4367 67.1359 12.6613 71.2232 12.6613 72.5168C12.6613 75.3734 12.275 90.2493 12.805 93.3217C12.8769 93.7258 12.8948 94.9476 12.9397 95.4415C12.9487 95.5764 12.9667 95.7021 12.9757 95.8459C12.9757 95.8818 12.9847 95.9177 12.9847 95.9536C12.9936 96.0436 12.9936 96.1244 13.0026 96.2141C13.0026 96.2321 13.0026 96.259 13.0026 96.2772C13.2272 99.7714 12.7601 116.13 20.9526 123.469C30.9148 132.38 49.5457 129.73 56.2291 121.987C62.9126 114.243 64.3768 107.677 69.4434 100.751C73.7461 94.8846 79.8815 93.0253 81.768 89.9259Z" fill="url(#paint47_radial_474_285)"/>
        <path d="M67.3404 88.1478C66.4781 89.0371 65.6337 90.052 64.8162 91.211C62.831 94.0496 60.136 96.3671 57.4501 94.0496C56.39 93.1334 55.7792 91.6062 55.4648 89.8187C55.6715 99.107 60.4594 97.2656 63.6126 94.0406C66.7745 90.8156 69.2179 85.2911 76.1708 85.2462C77.4285 85.2372 78.6501 85.5067 79.5575 85.7134C76.7636 84.0156 71.8949 83.8 67.3404 88.1478Z" fill="url(#paint48_radial_474_285)"/>
        <path d="M56.2373 121.978C62.9208 114.234 64.385 107.668 69.4513 100.742C73.7453 94.8848 79.8807 93.0163 81.7672 89.9172C81.9828 89.2703 81.911 88.5877 81.6236 87.9318C80.6623 90.5729 76.9434 91.4173 73.0176 94.4805C67.3315 98.9093 61.9775 105.125 59.2287 112.231C56.4798 119.337 47.5687 122.678 41.5859 121.654L56.2373 121.978Z" fill="url(#paint49_linear_474_285)"/>
        <defs>
        <linearGradient id="paint0_linear_474_285" x1="128.237" y1="124.897" x2="128.761" y2="137.948" gradientUnits="userSpaceOnUse">
        <stop offset="0.00132565" stop-color="#BE8D68"/>
        <stop offset="1" stop-color="#CD9870"/>
        </linearGradient>
        <radialGradient id="paint1_radial_474_285" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(115.455 145.113) rotate(180) scale(10.997 36.1879)">
        <stop stop-color="#B37E53" stop-opacity="0"/>
        <stop offset="1" stop-color="#B37E53"/>
        </radialGradient>
        <linearGradient id="paint2_linear_474_285" x1="127.936" y1="137.686" x2="165.642" y2="136.102" gradientUnits="userSpaceOnUse">
        <stop offset="0.3118" stop-color="#B37E53" stop-opacity="0"/>
        <stop offset="1" stop-color="#B37E53"/>
        </linearGradient>
        <radialGradient id="paint3_radial_474_285" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(142.01 147.777) rotate(180) scale(9.98752 35.252)">
        <stop stop-color="#B37E53" stop-opacity="0"/>
        <stop offset="1" stop-color="#B37E53"/>
        </radialGradient>
        <linearGradient id="paint4_linear_474_285" x1="130.689" y1="138.439" x2="161.601" y2="158.886" gradientUnits="userSpaceOnUse">
        <stop offset="0.3118" stop-color="#F0C492" stop-opacity="0"/>
        <stop offset="1" stop-color="#E6AF74"/>
        </linearGradient>
        <linearGradient id="paint5_linear_474_285" x1="135.935" y1="137.694" x2="124.418" y2="104.709" gradientUnits="userSpaceOnUse">
        <stop offset="0.00132565" stop-color="#BE8D68"/>
        <stop offset="1" stop-color="#CD9870"/>
        </linearGradient>
        <linearGradient id="paint6_linear_474_285" x1="152.974" y1="66.3796" x2="148.825" y2="84.5797" gradientUnits="userSpaceOnUse">
        <stop offset="0.00132565" stop-color="#BE8D68"/>
        <stop offset="1" stop-color="#CD9870"/>
        </linearGradient>
        <linearGradient id="paint7_linear_474_285" x1="141.089" y1="46.5502" x2="139.264" y2="82.5268" gradientUnits="userSpaceOnUse">
        <stop offset="0.00132565" stop-color="#BE8D68"/>
        <stop offset="1" stop-color="#CD9870"/>
        </linearGradient>
        <linearGradient id="paint8_linear_474_285" x1="114.558" y1="54.7729" x2="118.478" y2="81.5812" gradientUnits="userSpaceOnUse">
        <stop offset="0.00132565" stop-color="#BE8D68"/>
        <stop offset="1" stop-color="#CD9870"/>
        </linearGradient>
        <linearGradient id="paint9_linear_474_285" x1="126.959" y1="49.6585" x2="129.318" y2="81.1837" gradientUnits="userSpaceOnUse">
        <stop offset="0.00132565" stop-color="#BE8D68"/>
        <stop offset="1" stop-color="#CD9870"/>
        </linearGradient>
        <radialGradient id="paint10_radial_474_285" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(-2522.04 -2026.97) rotate(139.209) scale(72.2506)">
        <stop stop-color="#B37E53" stop-opacity="0"/>
        <stop offset="1" stop-color="#B37E53"/>
        </radialGradient>
        <radialGradient id="paint11_radial_474_285" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(-2522.02 -2026.97) rotate(139.209) scale(72.2503)">
        <stop stop-color="#B37E53" stop-opacity="0"/>
        <stop offset="1" stop-color="#B37E53"/>
        </radialGradient>
        <radialGradient id="paint12_radial_474_285" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(169.108 42.8171) rotate(180) scale(72.2506 72.2505)">
        <stop stop-color="#B37E53" stop-opacity="0"/>
        <stop offset="1" stop-color="#B37E53"/>
        </radialGradient>
        <linearGradient id="paint13_linear_474_285" x1="145.969" y1="55.4125" x2="145.969" y2="145.406" gradientUnits="userSpaceOnUse">
        <stop stop-color="#D2A374" stop-opacity="0"/>
        <stop offset="1" stop-color="#B88653"/>
        </linearGradient>
        <radialGradient id="paint14_radial_474_285" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(169.107 42.8175) rotate(180) scale(72.2502)">
        <stop stop-color="#B37E53" stop-opacity="0"/>
        <stop offset="1" stop-color="#B37E53"/>
        </radialGradient>
        <linearGradient id="paint15_linear_474_285" x1="-1680.36" y1="-2025.33" x2="-1622.34" y2="-1958.1" gradientUnits="userSpaceOnUse">
        <stop stop-color="#D2A374" stop-opacity="0"/>
        <stop offset="1" stop-color="#B88653"/>
        </linearGradient>
        <radialGradient id="paint16_radial_474_285" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(133.197 58.4479) rotate(180) scale(72.2504)">
        <stop stop-color="#B37E53" stop-opacity="0"/>
        <stop offset="1" stop-color="#B37E53"/>
        </radialGradient>
        <linearGradient id="paint17_linear_474_285" x1="142.329" y1="97.8319" x2="193.554" y2="143.353" gradientUnits="userSpaceOnUse">
        <stop stop-color="#D2A374" stop-opacity="0"/>
        <stop offset="1" stop-color="#B88653"/>
        </linearGradient>
        <radialGradient id="paint18_radial_474_285" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(145.724 113.459) rotate(-166.964) scale(29.4552 74.7517)">
        <stop offset="0.6147" stop-color="#B37E53" stop-opacity="0"/>
        <stop offset="1" stop-color="#B37E53"/>
        </radialGradient>
        <radialGradient id="paint19_radial_474_285" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(113.626 111.249) rotate(-140.236) scale(11.7902 5.4723)">
        <stop stop-color="#F8DBBA"/>
        <stop offset="1" stop-color="#CD9870" stop-opacity="0"/>
        </radialGradient>
        <radialGradient id="paint20_radial_474_285" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(138.784 112.185) rotate(-41.0309) scale(15.1731 6.92297)">
        <stop stop-color="#F8DBBA"/>
        <stop offset="1" stop-color="#CD9870" stop-opacity="0"/>
        </radialGradient>
        <radialGradient id="paint21_radial_474_285" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(169.108 42.8169) rotate(180) scale(72.2505 72.2503)">
        <stop stop-color="#B37E53" stop-opacity="0"/>
        <stop offset="1" stop-color="#B37E53"/>
        </radialGradient>
        <radialGradient id="paint22_radial_474_285" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(128.347 106.914) rotate(180) scale(57.5659 128.683)">
        <stop stop-color="#B37E53" stop-opacity="0"/>
        <stop offset="1" stop-color="#B37E53"/>
        </radialGradient>
        <radialGradient id="paint23_radial_474_285" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(99.4873 90.6276) rotate(180) scale(34.7483)">
        <stop stop-color="#B37E53" stop-opacity="0"/>
        <stop offset="1" stop-color="#B37E53"/>
        </radialGradient>
        <linearGradient id="paint24_linear_474_285" x1="111.571" y1="103.717" x2="60.35" y2="112.649" gradientUnits="userSpaceOnUse">
        <stop stop-color="#C86F34" stop-opacity="0"/>
        <stop offset="1" stop-color="#C86F34"/>
        </linearGradient>
        <linearGradient id="paint25_linear_474_285" x1="38.767" y1="124.897" x2="38.2431" y2="137.948" gradientUnits="userSpaceOnUse">
        <stop offset="0.00132565" stop-color="#BE8D68"/>
        <stop offset="1" stop-color="#CD9870"/>
        </linearGradient>
        <radialGradient id="paint26_radial_474_285" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(51.5484 145.113) scale(10.997 36.1879)">
        <stop stop-color="#B37E53" stop-opacity="0"/>
        <stop offset="1" stop-color="#B37E53"/>
        </radialGradient>
        <linearGradient id="paint27_linear_474_285" x1="39.0678" y1="137.686" x2="1.3622" y2="136.102" gradientUnits="userSpaceOnUse">
        <stop offset="0.3118" stop-color="#B37E53" stop-opacity="0"/>
        <stop offset="1" stop-color="#B37E53"/>
        </linearGradient>
        <radialGradient id="paint28_radial_474_285" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(24.9928 147.777) scale(9.98754 35.252)">
        <stop stop-color="#B37E53" stop-opacity="0"/>
        <stop offset="1" stop-color="#B37E53"/>
        </radialGradient>
        <linearGradient id="paint29_linear_474_285" x1="36.3139" y1="138.439" x2="5.40199" y2="158.887" gradientUnits="userSpaceOnUse">
        <stop offset="0.3118" stop-color="#F0C492" stop-opacity="0"/>
        <stop offset="1" stop-color="#E6AF74"/>
        </linearGradient>
        <linearGradient id="paint30_linear_474_285" x1="31.067" y1="137.694" x2="42.5835" y2="104.71" gradientUnits="userSpaceOnUse">
        <stop offset="0.00132565" stop-color="#BE8D68"/>
        <stop offset="1" stop-color="#CD9870"/>
        </linearGradient>
        <linearGradient id="paint31_linear_474_285" x1="14.0238" y1="66.3758" x2="18.173" y2="84.5759" gradientUnits="userSpaceOnUse">
        <stop offset="0.00132565" stop-color="#BE8D68"/>
        <stop offset="1" stop-color="#CD9870"/>
        </linearGradient>
        <linearGradient id="paint32_linear_474_285" x1="25.9076" y1="46.5465" x2="27.7325" y2="82.5228" gradientUnits="userSpaceOnUse">
        <stop offset="0.00132565" stop-color="#BE8D68"/>
        <stop offset="1" stop-color="#CD9870"/>
        </linearGradient>
        <linearGradient id="paint33_linear_474_285" x1="52.4399" y1="54.7691" x2="48.5196" y2="81.5772" gradientUnits="userSpaceOnUse">
        <stop offset="0.00132565" stop-color="#BE8D68"/>
        <stop offset="1" stop-color="#CD9870"/>
        </linearGradient>
        <linearGradient id="paint34_linear_474_285" x1="40.0385" y1="49.6547" x2="37.6792" y2="81.1798" gradientUnits="userSpaceOnUse">
        <stop offset="0.00132565" stop-color="#BE8D68"/>
        <stop offset="1" stop-color="#CD9870"/>
        </linearGradient>
        <radialGradient id="paint35_radial_474_285" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(2689.02 -2026.98) rotate(40.7908) scale(72.2505)">
        <stop stop-color="#B37E53" stop-opacity="0"/>
        <stop offset="1" stop-color="#B37E53"/>
        </radialGradient>
        <radialGradient id="paint36_radial_474_285" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(2689.01 -2026.98) rotate(40.7909) scale(72.2504)">
        <stop stop-color="#B37E53" stop-opacity="0"/>
        <stop offset="1" stop-color="#B37E53"/>
        </radialGradient>
        <radialGradient id="paint37_radial_474_285" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(-2.10506 42.8171) scale(72.2502)">
        <stop stop-color="#B37E53" stop-opacity="0"/>
        <stop offset="1" stop-color="#B37E53"/>
        </radialGradient>
        <linearGradient id="paint38_linear_474_285" x1="21.0332" y1="55.4125" x2="21.0332" y2="145.407" gradientUnits="userSpaceOnUse">
        <stop stop-color="#D2A374" stop-opacity="0"/>
        <stop offset="1" stop-color="#B88653"/>
        </linearGradient>
        <radialGradient id="paint39_radial_474_285" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(-2.10456 42.8175) scale(72.2503)">
        <stop stop-color="#B37E53" stop-opacity="0"/>
        <stop offset="1" stop-color="#B37E53"/>
        </radialGradient>
        <linearGradient id="paint40_linear_474_285" x1="1847.36" y1="-2025.33" x2="1789.34" y2="-1958.1" gradientUnits="userSpaceOnUse">
        <stop stop-color="#D2A374" stop-opacity="0"/>
        <stop offset="1" stop-color="#B88653"/>
        </linearGradient>
        <radialGradient id="paint41_radial_474_285" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(33.8047 58.4479) scale(72.2503)">
        <stop stop-color="#B37E53" stop-opacity="0"/>
        <stop offset="1" stop-color="#B37E53"/>
        </radialGradient>
        <linearGradient id="paint42_linear_474_285" x1="24.6737" y1="97.8322" x2="-26.5511" y2="143.353" gradientUnits="userSpaceOnUse">
        <stop stop-color="#D2A374" stop-opacity="0"/>
        <stop offset="1" stop-color="#B88653"/>
        </linearGradient>
        <radialGradient id="paint43_radial_474_285" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(21.2071 113.497) rotate(-13.0362) scale(29.4552 74.7517)">
        <stop offset="0.6147" stop-color="#B37E53" stop-opacity="0"/>
        <stop offset="1" stop-color="#B37E53"/>
        </radialGradient>
        <radialGradient id="paint44_radial_474_285" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(53.4451 111.204) rotate(-39.7642) scale(11.7902 5.4723)">
        <stop stop-color="#F8DBBA"/>
        <stop offset="1" stop-color="#CD9870" stop-opacity="0"/>
        </radialGradient>
        <radialGradient id="paint45_radial_474_285" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(28.1972 112.187) rotate(-138.969) scale(15.1731 6.92297)">
        <stop stop-color="#F8DBBA"/>
        <stop offset="1" stop-color="#CD9870" stop-opacity="0"/>
        </radialGradient>
        <radialGradient id="paint46_radial_474_285" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(-2.10462 42.8169) scale(72.2505)">
        <stop stop-color="#B37E53" stop-opacity="0"/>
        <stop offset="1" stop-color="#B37E53"/>
        </radialGradient>
        <radialGradient id="paint47_radial_474_285" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(38.6565 106.914) scale(57.5659 128.683)">
        <stop stop-color="#B37E53" stop-opacity="0"/>
        <stop offset="1" stop-color="#B37E53"/>
        </radialGradient>
        <radialGradient id="paint48_radial_474_285" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(67.5151 90.6278) scale(34.7483)">
        <stop stop-color="#B37E53" stop-opacity="0"/>
        <stop offset="1" stop-color="#B37E53"/>
        </radialGradient>
        <linearGradient id="paint49_linear_474_285" x1="55.4321" y1="103.717" x2="106.653" y2="112.649" gradientUnits="userSpaceOnUse">
        <stop stop-color="#C86F34" stop-opacity="0"/>
        <stop offset="1" stop-color="#C86F34"/>
        </linearGradient>
        </defs>
        </svg> 
        </Tilt>
        </div>
        </Col>

                <Col>
                <div id="studentsitemtext">
                <h2 id="bold" className="center">Community</h2>
                <p id="centertextchunk">We want to foster a supportive environment where everyone can learn together, give each other constructive feedback, and develop valuable skills.</p>
                </div>
                </Col>

                <Col>
                <div id="centereddiv"><Tilt>
                <svg id="simpleshadow" width="191" height="191" viewBox="0 0 191 191" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M163.35 98.3309C159.443 94.9628 145.133 91.3314 133.771 104.503C129.348 109.621 125.74 107.895 123.379 105.522C121.809 103.952 117.59 89.0781 120.047 80.6765L99.205 79.6816C98.8214 83.1335 95.981 87.424 94.7344 87.5081C93.4879 87.6038 92.4692 84.5956 92.2414 81.6353C92.0137 78.6748 73.7242 92.2423 72.6217 92.7816C71.8904 93.1412 64.6393 93.5007 58.6947 95.8617C45.1272 92.8776 33.9929 90.8998 30.3254 90.5882C27.4609 100.045 27.1133 113.025 27.1133 119.605C28.276 153.835 40.7407 174.941 77.0443 176.487C78.1468 176.547 79.2494 176.607 80.3521 176.607C80.6998 176.607 81.0113 176.643 81.359 176.643C85.7095 176.643 89.6765 176.115 93.3201 175.192C102.861 173.119 111.214 168.84 116.272 162.979C129.312 147.866 129.827 138.302 141.058 125.873C150.562 115.35 162.787 116.968 167.413 111.598C171.297 107.116 165.544 100.224 163.35 98.3309Z" fill="url(#paint0_linear_474_340)"/>
                <path d="M167.414 111.622C169.535 109.165 168.792 106.013 167.33 103.34C162.5 114.187 149.005 106.576 123.716 135.892C98.4147 165.208 73.9767 159.012 65.6469 154.373C57.3172 149.735 51.5402 133.986 47.6691 114.534C46.4825 108.566 44.2652 101.59 41.4126 94.5906C37.3257 97.0598 27.1621 115.877 27.1621 115.877C27.8332 129.564 30.3382 151.161 47.4172 165.891C67.169 182.934 103.245 178.116 116.285 162.991C129.337 147.877 129.84 138.313 141.07 125.884C150.563 115.373 162.776 116.991 167.414 111.622Z" fill="url(#paint1_radial_474_340)"/>
                <path d="M96.8916 83.2765C96.9396 82.3535 96.9876 81.3469 97.0234 80.2802C97.6948 62.7815 97.5868 27.5329 98.3898 22.8945C99.2287 17.9685 101.937 14.337 108.997 14.325C116.056 14.313 119.508 20.9409 118.681 29.882C117.854 38.811 119.64 72.2021 120.179 83.4443C121.018 100.859 123.835 127.85 123.835 127.85L97.6226 109.92L96.8916 83.2765Z" fill="url(#paint2_linear_474_340)"/>
                <path d="M100.811 53.7453C100.308 35.7433 99.6964 23.3145 102.165 16.1832C99.996 17.7174 98.8694 20.0905 98.378 22.907C97.5871 27.5453 97.6828 62.7941 97.0117 80.2926C96.9756 81.3593 96.9279 82.3661 96.8799 83.2889L97.1315 92.6016L98.39 95.49C98.4019 95.49 101.494 77.9915 100.811 53.7453Z" fill="url(#paint3_radial_474_340)"/>
                <path d="M29.3425 38.7262C19.3827 40.6797 21.0247 50.6515 22.9543 60.1437C25.0278 70.3073 30.7089 108.864 30.7089 108.864L53.9963 110.314C53.9963 110.314 54.5955 106.838 53.7205 101.877C51.1079 87.1946 44.7676 57.1835 42.4064 47.883C39.3022 35.7299 29.3425 38.7262 29.3425 38.7262Z" fill="url(#paint4_linear_474_340)"/>
                <path d="M53.7089 101.877C51.096 87.1953 44.7557 57.1842 42.3945 47.8837C40.6927 41.1958 36.9293 39.1104 33.873 38.5591C41.6157 44.5037 44.9236 74.8866 49.1423 87.8545C52.7021 98.7852 53.7686 103.304 54.0804 104.766C54.0083 103.879 53.9006 102.92 53.7089 101.877Z" fill="url(#paint5_radial_474_340)"/>
                <path d="M27.1133 119.614C27.1133 125.248 29.846 127.777 31.2004 128.687C31.6557 128.987 31.9554 129.119 31.9554 129.119L57.4123 147.744L104.011 138.084L99.0253 95.7039C92.0378 94.7931 85.6495 94.1697 79.4891 93.6783C79.1894 93.6545 75.6418 83.4071 75.3422 83.383C75.0306 83.3591 73.6163 92.8635 73.3047 92.8394C58.0474 91.7008 48.4353 92.0723 30.3254 90.5741C27.4609 100.067 27.1133 113.047 27.1133 119.614Z" fill="url(#paint6_linear_474_340)"/>
                <path opacity="0.16" d="M26.3584 51.4669C24.0332 50.9755 24.908 46.3373 26.8736 44.0121C28.8393 41.6869 32.3269 40.2007 34.6402 40.7043C36.9534 41.2075 36.0665 44.0479 35.3113 46.5529C34.0049 50.8676 28.6834 51.9584 26.3584 51.4669Z" fill="url(#paint7_radial_474_340)"/>
                <path opacity="0.16" d="M104.982 17.3689C105.725 15.1157 110.243 16.482 112.341 18.6993C114.438 20.9166 115.529 24.5361 114.786 26.7894C114.042 29.0425 111.31 27.844 108.901 26.8133C104.742 25.0274 104.239 19.6222 104.982 17.3689Z" fill="url(#paint8_radial_474_340)"/>
                <g opacity="0.16">
                <path opacity="0.75" d="M34.4239 120.839C30.7804 125.274 41.8667 150.85 52.7974 157.298C63.74 163.734 77.4393 161.769 80.1119 156.639C82.7847 151.509 71.4106 143.563 62.1101 134.754C46.0618 119.545 38.0675 116.392 34.4239 120.839Z" fill="url(#paint9_radial_474_340)"/>
                </g>
                <path d="M33.2257 134.79C26.0706 122.193 32.1231 100.428 30.7687 96.1735C29.4265 91.9068 29.8698 75.7384 26.2742 61.1525C24.093 52.2834 23.3139 46.0389 24.8959 40.6097C19.994 44.397 21.3723 52.4271 22.9424 60.1457C24.2609 66.6058 27.0294 84.5356 28.8752 96.7487C27.341 105.103 27.0893 114.379 27.0893 119.629C28.18 151.654 39.1706 172.184 70.2964 175.972C51.2157 172.688 39.9255 146.584 33.2257 134.79Z" fill="url(#paint10_radial_474_340)"/>
                <path d="M77.4754 176.522C76.6006 176.39 75.5697 176.318 74.3354 176.318C73.1127 176.318 71.9261 176.198 70.7637 176.019C72.8971 176.27 75.1383 176.438 77.4754 176.522Z" fill="url(#paint11_linear_474_340)"/>
                <path d="M167.318 103.339C166.083 101.098 164.345 99.1925 163.339 98.3294C159.431 94.9616 145.121 91.33 133.759 104.502C129.337 109.62 122.049 109.32 121.162 98.7489C120.743 93.4274 120.383 88.094 120.156 83.4438C119.616 72.2016 117.83 38.8105 118.657 29.8815C119.197 24.0686 117.89 19.2745 115.002 16.6017C117.471 24.3802 116.464 35.9102 117.183 66.5325C117.603 84.4265 117.77 90.8028 118.621 98.833C121.162 121.137 130.667 114.977 137.45 109.344C144.234 103.711 150.167 93.5953 162.787 101.062C167.102 103.603 167.402 104.598 167.318 103.339Z" fill="url(#paint12_radial_474_340)"/>
                <path d="M89.4014 114.51C100.212 114.234 101.734 103.627 99.5289 81.5744L99.0495 76.8161L75.9896 94.794L53.5533 98.1859L50.413 86.1047C50.3292 86.1047 50.4971 86.1047 50.413 86.1047C50.413 86.1047 45.9186 120.682 67.0486 118.561C67.0486 118.561 66.0418 118.753 67.0486 118.561C76.8047 116.715 76.6968 103.316 76.6968 103.316C76.6968 103.316 80.0526 114.75 89.4014 114.51Z" fill="url(#paint13_linear_474_340)"/>
                <path d="M65.6585 117.374C57.3887 117.374 53.2538 113.455 52.1152 102.285C50.9766 91.1144 45.9905 71.063 61.164 69.9124C74.7912 68.8818 75.9897 78.6018 75.9897 94.794C75.9897 110.986 72.7778 117.374 65.6585 117.374Z" fill="url(#paint14_linear_474_340)"/>
                <path d="M64.5681 100.127C58.2518 104.035 54.0092 104.118 51.3125 98.785C51.3724 99.3842 51.4443 99.9957 51.4923 100.547C52.6428 113.251 56.7777 117.698 65.0356 117.698C70.8485 117.698 74.0606 112.856 75.0433 101.038C73.4133 97.6225 68.2955 97.8261 64.5681 100.127Z" fill="url(#paint15_linear_474_340)"/>
                <path d="M64.5082 104.91C57.7246 104.91 54.6684 100.523 53.5179 91.5339C52.9305 86.9076 49.0714 70.7755 61.5241 69.9005C73.4614 69.0615 73.4614 79.7764 73.713 88.8613C74.0726 101.949 70.3571 104.91 64.5082 104.91Z" fill="url(#paint16_linear_474_340)"/>
                <path d="M60.7324 116.703C62.1828 117.134 63.8008 117.374 65.6585 117.374C72.7778 117.374 75.9897 110.998 75.9897 94.7935C75.9897 79.1886 75.0429 69.5524 62.6501 69.8762C67.7798 73.184 71.9627 80.7109 71.9627 94.3022C71.9627 113.646 67.9477 116.667 60.7324 116.703Z" fill="url(#paint17_linear_474_340)"/>
                <path d="M68.0191 70.5246C69.901 71.0998 71.5909 72.3343 72.7294 73.9762C73.868 75.6062 74.5752 77.5119 75.0308 79.4295C75.9055 83.3008 76.0853 87.2798 76.2052 91.2111C76.2412 95.1543 76.4568 99.0855 76.1333 103.065C75.7377 106.984 74.9708 111.071 72.4897 114.379C70.0688 117.879 65.2387 118.741 61.4273 117.698C59.4497 117.219 57.58 116.224 56.1538 114.75C54.7036 113.3 53.7448 111.466 53.0376 109.608C51.6473 105.845 51.2517 101.83 51.0361 97.9109C50.7723 93.9796 50.4008 90.0486 50.4249 86.1054C51.0361 90.0126 51.2517 93.9438 51.5755 97.8629C51.851 101.782 52.2946 105.713 53.6968 109.357C55.0152 112.977 57.8078 116.141 61.6312 116.932C65.3105 117.879 69.6133 117.075 71.8425 113.863C74.1918 110.831 75.0427 106.852 75.4741 103.005C75.8456 99.1214 75.69 95.1543 75.7019 91.235C75.642 87.304 75.5102 83.3608 74.707 79.5135C74.3236 77.5959 73.6763 75.7141 72.5977 74.0841C71.519 72.4181 69.889 71.1597 68.0191 70.5246Z" fill="#975500"/>
                <path d="M89.6535 112.76C81.7552 112.448 76.7572 107.306 76.6134 96.3039C76.4936 87.0272 72.0349 66.6643 86.5493 66.1729C100.476 65.7054 100.488 75.2218 100.081 91.0542C99.6731 106.911 96.449 113.023 89.6535 112.76Z" fill="url(#paint18_linear_474_340)"/>
                <path d="M89.0417 95.6564C82.9173 99.2159 78.8542 99.1321 76.4092 93.8586C76.4572 94.4459 76.5052 95.0332 76.541 95.5845C77.32 107.953 81.1553 112.436 89.0536 112.747C94.6029 112.963 98.522 108.505 99.7566 97.0707C98.2823 93.6669 92.6614 93.559 89.0417 95.6564Z" fill="url(#paint19_linear_474_340)"/>
                <path d="M88.4785 96.5558C82.0065 96.3041 79.5856 95.6688 78.7105 86.8835C78.255 82.3771 74.9832 66.5566 86.8966 66.1731C98.3186 65.8014 98.0548 76.2047 98.0667 85.038C98.0789 97.7662 94.0636 96.7716 88.4785 96.5558Z" fill="url(#paint20_linear_474_340)"/>
                <path d="M84.9551 111.933C86.3334 112.412 87.8795 112.7 89.6534 112.772C96.449 113.035 99.685 106.911 100.093 91.0783C100.488 75.8091 100.44 66.4484 87.9755 66.2087C92.7935 69.6367 96.6049 77.1513 96.2573 90.443C95.7659 109.356 91.8466 112.16 84.9551 111.933Z" fill="url(#paint21_linear_474_340)"/>
                <path d="M93.0807 67.0238C94.8546 67.6469 96.5206 68.7017 97.6831 70.2357C98.8455 71.758 99.4809 73.6157 99.8523 75.4734C100.535 79.2247 100.476 83.0361 100.464 86.7995C100.416 90.5748 100.368 94.3621 99.9963 98.1375C99.5768 101.877 99.0375 105.748 97.0719 109.164C96.0889 110.842 94.4709 112.268 92.5413 112.796C90.5998 113.359 88.6342 113.119 86.7645 112.784C84.8708 112.4 83.025 111.621 81.5268 110.375C80.0167 109.128 78.9261 107.462 78.1948 105.688C76.7688 102.117 76.445 98.2454 76.3252 94.4939L75.8818 83.1798L76.7089 94.47C76.9125 98.2215 77.2601 102.021 78.6984 105.473C80.0647 108.96 83.2048 111.513 86.8844 112.1C88.6941 112.4 90.6118 112.592 92.3136 112.053C94.0395 111.549 95.4417 110.303 96.3408 108.721C98.2105 105.568 98.8336 101.757 99.2891 98.0656C99.7086 94.3382 99.8044 90.5629 99.9003 86.7995C99.9722 83.0361 100.056 79.2488 99.4448 75.5572C99.1452 73.7236 98.5939 71.8898 97.5152 70.3675C96.4485 68.8216 94.8305 67.7188 93.0807 67.0238Z" fill="#975500"/>
                <path d="M100.344 89.1371C100.344 89.1371 100.224 89.113 100.164 89.101C101.231 106.048 98.9062 114.27 89.4137 114.51C80.0652 114.75 76.6974 103.292 76.6974 103.292C76.6974 103.292 76.8051 116.691 67.0491 118.537C67.0491 118.537 66.8934 118.573 66.7616 118.597C66.5818 118.633 66.6296 118.621 66.7616 118.597C66.8335 118.585 66.8815 118.573 67.0491 118.537C59.3066 119.316 55.0158 115.145 52.6787 109.572C55.0039 116.092 59.2706 119.711 65.0715 119.711C73.8449 119.711 75.9542 112.065 77.752 112.065C79.5498 112.065 86.633 117.35 90.3246 117.35C102.586 117.374 100.788 97.4668 100.344 89.1371Z" fill="url(#paint22_linear_474_340)"/>
                <defs>
                <linearGradient id="paint0_linear_474_340" x1="14.5667" y1="147.609" x2="106.303" y2="119.285" gradientUnits="userSpaceOnUse">
                <stop offset="0.00132565" stop-color="#BE8D68"/>
                <stop offset="1" stop-color="#CD9870"/>
                </linearGradient>
                <radialGradient id="paint1_radial_474_340" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(79.1213 92.5393) scale(71.4478 102.964)">
                <stop offset="0.6147" stop-color="#B37E53" stop-opacity="0"/>
                <stop offset="1" stop-color="#B37E53"/>
                </radialGradient>
                <linearGradient id="paint2_linear_474_340" x1="117.67" y1="-72.6258" x2="110.06" y2="97.3193" gradientUnits="userSpaceOnUse">
                <stop offset="0.00132565" stop-color="#BE8D68"/>
                <stop offset="1" stop-color="#CD9870"/>
                </linearGradient>
                <radialGradient id="paint3_radial_474_340" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(85.6247 -28.0997) scale(115.585 115.585)">
                <stop offset="0.6147" stop-color="#B37E53" stop-opacity="0"/>
                <stop offset="1" stop-color="#B37E53"/>
                </radialGradient>
                <linearGradient id="paint4_linear_474_340" x1="-3.68786" y1="88.485" x2="88.0487" y2="60.1609" gradientUnits="userSpaceOnUse">
                <stop offset="0.00132565" stop-color="#BE8D68"/>
                <stop offset="1" stop-color="#CD9870"/>
                </linearGradient>
                <radialGradient id="paint5_radial_474_340" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(27.2852 5.29666) scale(65.889)">
                <stop offset="0.6147" stop-color="#B37E53" stop-opacity="0"/>
                <stop offset="1" stop-color="#B37E53"/>
                </radialGradient>
                <linearGradient id="paint6_linear_474_340" x1="9.77008" y1="132.072" x2="101.507" y2="103.748" gradientUnits="userSpaceOnUse">
                <stop offset="0.00132565" stop-color="#BE8D68"/>
                <stop offset="1" stop-color="#CD9870"/>
                </linearGradient>
                <radialGradient id="paint7_radial_474_340" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(30.7922 46.1466) rotate(130.273) scale(6.91604 3.66114)">
                <stop stop-color="#F8DBBA"/>
                <stop offset="1" stop-color="#CD9870" stop-opacity="0"/>
                </radialGradient>
                <radialGradient id="paint8_radial_474_340" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(109.788 22.0921) rotate(-133.437) scale(6.91773 3.66195)">
                <stop stop-color="#F8DBBA"/>
                <stop offset="1" stop-color="#CD9870" stop-opacity="0"/>
                </radialGradient>
                <radialGradient id="paint9_radial_474_340" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(56.0091 142.83) rotate(-138.629) scale(32.0183 14.632)">
                <stop stop-color="#F8DBBA"/>
                <stop offset="1" stop-color="#CD9870" stop-opacity="0"/>
                </radialGradient>
                <radialGradient id="paint10_radial_474_340" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(70.0018 101.537) scale(50.8736 79.7038)">
                <stop offset="0.6147" stop-color="#B37E53" stop-opacity="0"/>
                <stop offset="1" stop-color="#B37E53"/>
                </radialGradient>
                <linearGradient id="paint11_linear_474_340" x1="111.426" y1="31.1662" x2="10.1803" y2="424.933" gradientUnits="userSpaceOnUse">
                <stop stop-color="#FFBC47" stop-opacity="0"/>
                <stop offset="1" stop-color="#C86F34"/>
                </linearGradient>
                <radialGradient id="paint12_radial_474_340" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(141.176 65.6209) scale(39.2837)">
                <stop offset="0.6147" stop-color="#B37E53" stop-opacity="0"/>
                <stop offset="1" stop-color="#B37E53"/>
                </radialGradient>
                <linearGradient id="paint13_linear_474_340" x1="75.2" y1="158.794" x2="75.2" y2="83.5229" gradientUnits="userSpaceOnUse">
                <stop stop-color="#473226" stop-opacity="0"/>
                <stop offset="1" stop-color="#3D2A20"/>
                </linearGradient>
                <linearGradient id="paint14_linear_474_340" x1="63.2034" y1="92.3394" x2="63.2034" y2="137.805" gradientUnits="userSpaceOnUse">
                <stop offset="0.00132565" stop-color="#BE8D68"/>
                <stop offset="1" stop-color="#CD9870"/>
                </linearGradient>
                <linearGradient id="paint15_linear_474_340" x1="63.1746" y1="100.666" x2="63.1746" y2="123.171" gradientUnits="userSpaceOnUse">
                <stop stop-color="#8D562E" stop-opacity="0"/>
                <stop offset="1" stop-color="#8D562E"/>
                </linearGradient>
                <linearGradient id="paint16_linear_474_340" x1="63.1225" y1="102.954" x2="63.1225" y2="63.2721" gradientUnits="userSpaceOnUse">
                <stop offset="0.00132565" stop-color="#BE8D68"/>
                <stop offset="1" stop-color="#CD9870"/>
                </linearGradient>
                <linearGradient id="paint17_linear_474_340" x1="45.7442" y1="82.9358" x2="155.223" y2="145.129" gradientUnits="userSpaceOnUse">
                <stop stop-color="#8D562E" stop-opacity="0"/>
                <stop offset="1" stop-color="#8D562E"/>
                </linearGradient>
                <linearGradient id="paint18_linear_474_340" x1="87.9156" y1="88.1971" x2="86.5003" y2="132.672" gradientUnits="userSpaceOnUse">
                <stop offset="0.00132565" stop-color="#BE8D68"/>
                <stop offset="1" stop-color="#CD9870"/>
                </linearGradient>
                <linearGradient id="paint19_linear_474_340" x1="88.0841" y1="94.3239" x2="88.0841" y2="125.607" gradientUnits="userSpaceOnUse">
                <stop stop-color="#8D562E" stop-opacity="0"/>
                <stop offset="1" stop-color="#8D562E"/>
                </linearGradient>
                <linearGradient id="paint20_linear_474_340" x1="87.6009" y1="94.8611" x2="88.6946" y2="60.4959" gradientUnits="userSpaceOnUse">
                <stop offset="0.00132565" stop-color="#BE8D68"/>
                <stop offset="1" stop-color="#CD9870"/>
                </linearGradient>
                <linearGradient id="paint21_linear_474_340" x1="70.5179" y1="78.4322" x2="176.535" y2="145.423" gradientUnits="userSpaceOnUse">
                <stop stop-color="#8D562E" stop-opacity="0"/>
                <stop offset="1" stop-color="#8D562E"/>
                </linearGradient>
                <linearGradient id="paint22_linear_474_340" x1="76.6805" y1="142.518" x2="76.6805" y2="90.2812" gradientUnits="userSpaceOnUse">
                <stop stop-color="#A46C3F" stop-opacity="0"/>
                <stop offset="1" stop-color="#804B24"/>
                </linearGradient>
                </defs>
                </svg>
                </Tilt>
                </div>
                </Col>

                <Col>
                <div id="studentsitemtext">
                <h2 id="bold" className="center">Openness</h2>
                <p id="centertextchunk">No idea is a bad idea! We’re not afraid of big, unexplored ideas and want to hear ALL of them. We also value open communication and collaboration.</p>
                </div>
                </Col>

                <Col>
                <div id="centereddiv"><Tilt>
                <svg id="simpleshadow" width="171" height="171" viewBox="0 0 171 171" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M32.4895 119.253C22.9623 128.495 35.4616 138.389 16.2852 151.824C16.2852 151.824 27.563 155.611 37.7824 142.745C41.2838 138.308 45.3552 136.475 45.3552 136.475C46.7395 137.289 45.6809 141.809 40.7545 145.514C40.7545 145.514 48.4495 145.718 53.2538 140.913C58.058 136.109 61.3152 129.188 61.3152 129.188C64.613 134.969 56.348 142.298 56.348 142.298C56.348 142.298 64.1245 140.262 73.3668 131.019C80.5324 123.854 85.6624 105.98 75.3618 95.7201C65.0609 85.4601 46.6988 87.2926 37.7009 96.3307C33.7516 100.28 29.7209 106.428 29.1916 111.477C29.1916 111.477 36.7645 103.293 39.4516 106.754C39.4516 106.754 26.6266 114.489 24.5502 121.859C24.5909 121.859 30.983 118.032 32.4895 119.253Z" fill="url(#paint0_linear_474_227)"/>
        <path d="M25.4057 126.298C15.4307 130.288 21.8636 138.675 17.5479 142.991C17.5479 142.991 23.2886 139.856 23.2072 133.708C23.1257 128.7 25.4057 126.298 25.4057 126.298Z" fill="url(#paint1_linear_474_227)"/>
        <path d="M27.7256 155.408C30.2906 155.123 34.6063 147.673 40.4691 148.894C40.4691 148.894 35.217 156.548 27.7256 155.408Z" fill="url(#paint2_linear_474_227)"/>
        <path opacity="0.5" d="M59.2801 140.465C62.4151 139.284 67.7486 136.679 73.3673 131.019C79.6779 124.709 84.9304 106.794 74.5885 96.4939C64.4915 86.397 46.7808 88.4327 38.2715 96.942C35.2179 99.9951 31.9608 104.596 30.6579 108.83C32.4901 107.202 35.2179 105.166 37.4979 104.922C38.6786 104.799 39.6151 105.207 40.2665 106.021L40.9179 106.835L39.7779 107.528C39.6558 107.609 29.7215 113.675 26.3423 119.864C28.6629 118.683 31.8386 117.38 33.1415 118.439L33.8744 119.049L33.0194 119.904C29.4773 123.365 29.2736 126.908 29.1108 131.019C28.8258 136.923 28.5001 143.559 18.4844 151.254C21.9044 151.703 29.6808 151.499 37.0501 142.257C40.7144 137.615 44.9486 135.661 45.1115 135.579L45.6001 135.376L45.9665 135.579C46.6586 135.987 46.9844 136.883 46.8623 138.063C46.6994 139.692 45.6408 142.013 43.3608 144.374C45.8851 144.048 49.8344 143.112 52.6436 140.303C57.2444 135.702 60.4201 129.024 60.4608 128.943L61.3565 127.029L62.2115 128.536C64.4101 132.444 61.9672 137.208 59.2801 140.465Z" fill="url(#paint3_linear_474_227)"/>
        <path d="M17.5479 142.991C20.3572 140.711 23.2479 136.233 23.2072 133.708C23.1664 131.388 23.8179 128.13 25.4057 126.298C18.4029 129.27 19.38 133.953 20.2757 136.273C20.6829 137.291 20.1535 140.304 17.5479 142.991Z" fill="url(#paint4_radial_474_227)"/>
        <path d="M22.9629 149.872C36.2358 145.515 39.7779 130.451 48.2872 127.845C56.8372 125.24 54.6793 133.383 51.3815 137.088C51.3815 137.088 62.6593 125.769 62.7 116.934C62.7815 108.099 74.6291 121.901 70.965 129.067C70.965 129.067 89.0829 110.949 74.5885 96.4953C60.0943 82.0415 40.8365 96.4547 40.8365 96.4547C40.8365 96.4547 51.8293 96.0065 50.8522 103.987C50.8522 103.987 42.3836 107.203 35.7065 112.537C35.7065 112.537 38.9636 112.903 41.2029 116.242C41.2029 116.242 31.2279 123.123 31.635 129.026C32.205 136.64 27.2379 148.488 22.9629 149.872Z" fill="url(#paint5_linear_474_227)"/>
        <path d="M32.2451 138.429C38.4337 136.882 43.8894 122.388 52.6023 123.202C57.4066 123.65 56.3073 127.803 56.3073 127.803C56.3073 127.803 59.6459 123.039 60.4194 116.647C61.193 110.255 73.1225 112.413 72.5931 124.464C72.5931 124.464 85.7031 107.405 69.7838 96.5345C52.2359 84.5645 39.3702 99.5875 39.3702 99.5875C39.3702 99.5875 51.3809 95.5975 53.2537 105.125C53.2537 105.125 43.3194 107.893 39.6552 111.558C39.6552 111.558 43.8894 112.168 44.9073 116.688C44.9073 116.688 35.1359 123.853 35.4209 126.703C36.113 133.503 32.2451 138.429 32.2451 138.429Z" fill="url(#paint6_linear_474_227)"/>
        <path opacity="0.5" d="M32.4895 119.253C22.9623 128.495 35.4616 138.389 16.2852 151.824C16.2852 151.824 27.563 155.611 37.7824 142.745C41.2838 138.308 45.3552 136.475 45.3552 136.475C46.7395 137.289 45.6809 141.809 40.7545 145.514C40.7545 145.514 48.4495 145.718 53.2538 140.913C58.058 136.109 61.3152 129.188 61.3152 129.188C64.613 134.969 56.348 142.298 56.348 142.298C56.348 142.298 64.1245 140.262 73.3668 131.019C80.5324 123.854 85.6624 105.98 75.3618 95.7201C65.0609 85.4601 46.6988 87.2926 37.7009 96.3307C33.7516 100.28 29.7209 106.428 29.1916 111.477C29.1916 111.477 36.7645 103.293 39.4516 106.754C39.4516 106.754 26.6266 114.489 24.5502 121.859C24.5909 121.859 30.983 118.032 32.4895 119.253Z" fill="url(#paint7_radial_474_227)"/>
        <path opacity="0.5" d="M32.4895 119.253C22.9623 128.495 35.4616 138.389 16.2852 151.824C16.2852 151.824 27.563 155.611 37.7824 142.745C41.2838 138.308 45.3552 136.475 45.3552 136.475C46.7395 137.289 45.6809 141.809 40.7545 145.514C40.7545 145.514 48.4495 145.718 53.2538 140.913C58.058 136.109 61.3152 129.188 61.3152 129.188C64.613 134.969 56.348 142.298 56.348 142.298C56.348 142.298 64.1245 140.262 73.3668 131.019C80.5324 123.854 85.6624 105.98 75.3618 95.7201C65.0609 85.4601 46.6988 87.2926 37.7009 96.3307C33.7516 100.28 29.7209 106.428 29.1916 111.477C29.1916 111.477 36.7645 103.293 39.4516 106.754C39.4516 106.754 26.6266 114.489 24.5502 121.859C24.5909 121.859 30.983 118.032 32.4895 119.253Z" fill="url(#paint8_radial_474_227)"/>
        <path d="M68.5627 134.074C77.8458 124.18 83.0976 106.51 73.4076 96.8202C63.7177 87.1302 45.8848 95.2734 37.9862 102.561C37.9862 102.561 44.2969 99.9146 49.2641 103.09C54.2312 106.266 40.0219 111.763 37.0498 120.475C37.0498 120.475 49.712 112.861 53.702 116.078C57.692 119.295 53.6612 129.433 49.8341 133.26C49.8341 133.26 58.9134 128.618 63.7991 122.633C71.2498 113.473 68.5627 134.074 68.5627 134.074Z" fill="url(#paint9_radial_474_227)"/>
        <path opacity="0.5" d="M32.4895 119.253C22.9623 128.495 35.4616 138.389 16.2852 151.824C16.2852 151.824 27.563 155.611 37.7824 142.745C41.2838 138.308 45.3552 136.475 45.3552 136.475C46.7395 137.289 45.6809 141.809 40.7545 145.514C40.7545 145.514 48.4495 145.718 53.2538 140.913C58.058 136.109 61.3152 129.188 61.3152 129.188C64.613 134.969 56.348 142.298 56.348 142.298C56.348 142.298 64.1245 140.262 73.3668 131.019C80.5324 123.854 85.6624 105.98 75.3618 95.7201C65.0609 85.4601 46.6988 87.2926 37.7009 96.3307C33.7516 100.28 29.7209 106.428 29.1916 111.477C29.1916 111.477 36.7645 103.293 39.4516 106.754C39.4516 106.754 26.6266 114.489 24.5502 121.859C24.5909 121.859 30.983 118.032 32.4895 119.253Z" fill="url(#paint10_radial_474_227)"/>
        <path opacity="0.5" d="M25.4057 126.298C15.4307 130.288 21.8636 138.675 17.5479 142.991C17.5479 142.991 23.2886 139.856 23.2072 133.708C23.1257 128.7 25.4057 126.298 25.4057 126.298Z" fill="url(#paint11_radial_474_227)"/>
        <path d="M27.7256 155.406C30.2906 155.121 34.0363 149.868 40.4691 148.891C40.4691 148.891 35.217 156.546 27.7256 155.406Z" fill="url(#paint12_radial_474_227)"/>
        <path opacity="0.5" d="M27.7256 155.408C30.2906 155.123 34.6063 147.673 40.4691 148.894C40.4691 148.894 35.217 156.548 27.7256 155.408Z" fill="url(#paint13_radial_474_227)"/>
        <path opacity="0.5" d="M32.4895 119.253C22.9623 128.495 35.4616 138.389 16.2852 151.824C16.2852 151.824 27.563 155.611 37.7824 142.745C41.2838 138.308 45.3552 136.475 45.3552 136.475C46.7395 137.289 45.6809 141.809 40.7545 145.514C40.7545 145.514 48.4495 145.718 53.2538 140.913C58.058 136.109 61.3152 129.188 61.3152 129.188C64.613 134.969 56.348 142.298 56.348 142.298C56.348 142.298 64.1245 140.262 73.3668 131.019C80.5324 123.854 85.6624 105.98 75.3618 95.7201C65.0609 85.4601 46.6988 87.2926 37.7009 96.3307C33.7516 100.28 29.7209 106.428 29.1916 111.477C29.1916 111.477 36.7645 103.293 39.4516 106.754C39.4516 106.754 26.6266 114.489 24.5502 121.859C24.5909 121.859 30.983 118.032 32.4895 119.253Z" fill="url(#paint14_radial_474_227)"/>
        <path opacity="0.25" d="M32.4895 119.253C22.9623 128.495 35.4616 138.389 16.2852 151.824C16.2852 151.824 27.563 155.611 37.7824 142.745C41.2838 138.308 45.3552 136.475 45.3552 136.475C46.7395 137.289 45.6809 141.809 40.7545 145.514C40.7545 145.514 48.4495 145.718 53.2538 140.913C58.058 136.109 61.3152 129.188 61.3152 129.188C64.613 134.969 56.348 142.298 56.348 142.298C56.348 142.298 64.1245 140.262 73.3668 131.019C80.5324 123.854 85.6624 105.98 75.3618 95.7201C65.0609 85.4601 46.6988 87.2926 37.7009 96.3307C33.7516 100.28 29.7209 106.428 29.1916 111.477C29.1916 111.477 36.7645 103.293 39.4516 106.754C39.4516 106.754 26.6266 114.489 24.5502 121.859C24.5909 121.859 30.983 118.032 32.4895 119.253Z" fill="url(#paint15_radial_474_227)"/>
        <path opacity="0.75" d="M32.4895 119.253C22.9623 128.495 35.4616 138.389 16.2852 151.824C16.2852 151.824 27.563 155.611 37.7824 142.745C41.2838 138.308 45.3552 136.475 45.3552 136.475C46.7395 137.289 45.6809 141.809 40.7545 145.514C40.7545 145.514 48.4495 145.718 53.2538 140.913C58.058 136.109 61.3152 129.188 61.3152 129.188C64.613 134.969 56.348 142.298 56.348 142.298C56.348 142.298 64.1245 140.262 73.3668 131.019C80.5324 123.854 85.6624 105.98 75.3618 95.7201C65.0609 85.4601 46.6988 87.2926 37.7009 96.3307C33.7516 100.28 29.7209 106.428 29.1916 111.477C29.1916 111.477 36.7645 103.293 39.4516 106.754C39.4516 106.754 26.6266 114.489 24.5502 121.859C24.5909 121.859 30.983 118.032 32.4895 119.253Z" fill="url(#paint16_radial_474_227)"/>
        <path d="M109.398 110.175C108.461 101.096 110.578 91.4465 115.383 82.7334L116.889 79.9646L93.3963 95.314L91.4426 109.442L82.2812 118.237C84.2356 125.891 84.3176 133.382 84.3176 133.382C99.9106 124.058 109.398 110.175 109.398 110.175Z" fill="url(#paint17_linear_474_227)"/>
        <path d="M84.3589 133.421C84.3589 133.421 84.3182 125.848 82.3232 118.276L86.1914 114.571C87.372 117.176 89.6926 123.039 90.2626 129.513C88.3895 130.816 86.4351 132.2 84.3589 133.421Z" fill="url(#paint18_linear_474_227)"/>
        <path opacity="0.75" d="M109.398 110.175C108.461 101.096 110.578 91.4465 115.383 82.7334L116.889 79.9646L93.3963 95.314L91.4426 109.442L82.2812 118.237C84.2356 125.891 84.3176 133.382 84.3176 133.382C99.9106 124.058 109.398 110.175 109.398 110.175Z" fill="url(#paint19_radial_474_227)"/>
        <path d="M111.678 91.1587C112.655 88.3087 113.876 85.4587 115.383 82.7305L116.238 81.1837C116.034 81.0618 115.831 80.9393 115.627 80.8174L93.4375 95.3524L92.0938 104.879C97.1425 102.803 104.308 98.5687 111.678 91.1587Z" fill="url(#paint20_linear_474_227)"/>
        <path d="M86.1912 55.7789C85.9468 55.2089 85.8243 54.8017 85.6212 54.2725C76.8674 58.4253 66.9739 59.606 57.4467 57.8146C57.4467 57.8146 43.3596 67.0567 34.6875 83.4238C34.6875 83.4238 42.7489 84.1975 51.2175 87.0882L59.8896 77.7644L73.8956 76.95C77.0306 69.9067 80.8168 62.3746 86.1912 55.7789Z" fill="url(#paint21_linear_474_227)"/>
        <path d="M34.6875 83.3824C34.6875 83.3824 42.7489 84.1562 51.2175 87.0468L54.9225 83.0568C51.9504 81.51 45.5175 78.5374 38.3925 77.2756C37.0896 79.1894 35.8275 81.225 34.6875 83.3824Z" fill="url(#paint22_linear_474_227)"/>
        <path opacity="0.75" d="M86.1912 55.7789C85.9468 55.2089 85.8243 54.8017 85.6212 54.2725C76.8674 58.4253 66.9739 59.606 57.4467 57.8146C57.4467 57.8146 43.3596 67.0567 34.6875 83.4238C34.6875 83.4238 42.7489 84.1975 51.2175 87.0882L59.8896 77.7644L73.8956 76.95C77.0306 69.9067 80.8168 62.3746 86.1912 55.7789Z" fill="url(#paint23_radial_474_227)"/>
        <path d="M85.6215 54.2307C82.2015 56.8364 71.4941 67.1778 67.748 77.2343L73.8553 76.868C76.9903 69.9057 81.7541 62.2107 87.1278 55.615L85.6215 54.2307Z" fill="url(#paint24_linear_474_227)"/>
        <path d="M89.3271 52.2392C92.9509 58.1428 97.7553 64.0057 103.088 69.2171C103.333 69.4613 103.618 69.7057 103.862 69.9499C108.382 74.2653 113.267 78.0929 118.235 81.1466C118.682 80.6985 119.13 80.2916 119.578 79.8435C132.403 67.0185 139.853 57.3692 146.246 45.1142C141.686 36.2792 134.195 28.7878 125.36 24.2278C113.105 30.6199 103.496 38.0299 90.6303 50.8957C90.1822 51.3435 89.7347 51.7913 89.3271 52.2392Z" fill="url(#paint25_linear_474_227)"/>
        <path d="M146.204 45.1124C154.875 28.4603 153.777 19.5031 152.352 18.0781C150.927 16.6531 142.01 15.5539 125.317 24.226C134.152 28.786 141.644 36.2774 146.204 45.1124Z" fill="url(#paint26_linear_474_227)"/>
        <path d="M74.6291 95.8419C79.2304 100.443 83.4641 103.822 84.6041 103.822C94.946 99.3033 107.282 91.7301 118.235 81.1445C113.308 78.0907 108.382 74.2639 103.862 69.948C103.618 69.7036 103.333 69.4594 103.088 69.2151C97.7554 64.0037 92.951 58.1408 89.3272 52.2372C78.7416 63.1894 71.1687 75.6069 66.6494 85.9489C66.8123 87.2107 70.1101 91.3639 74.6291 95.8419Z" fill="url(#paint27_linear_474_227)"/>
        <path opacity="0.75" d="M74.6291 95.8419C79.2304 100.443 83.4641 103.822 84.6041 103.822C94.946 99.3033 107.282 91.7301 118.235 81.1445C113.308 78.0907 108.382 74.2639 103.862 69.948C103.618 69.7036 103.333 69.4594 103.088 69.2151C97.7554 64.0037 92.951 58.1408 89.3272 52.2372C78.7416 63.1894 71.1687 75.6069 66.6494 85.9489C66.8123 87.2107 70.1101 91.3639 74.6291 95.8419Z" fill="url(#paint28_radial_474_227)"/>
        <path opacity="0.5" d="M103.862 69.948C103.618 69.7036 103.333 69.4594 103.088 69.2151C97.7553 64.0037 92.9509 58.1408 89.3271 52.2372C89.0421 52.5222 88.7978 52.8072 88.5534 53.0922C95.9634 67.098 118.601 80.4519 101.338 87.4957C90.0197 92.1369 84.9303 97.6339 82.2021 102.519C83.3421 103.374 84.2378 103.822 84.6453 103.822C94.9865 99.3033 107.323 91.7301 118.275 81.1445C113.267 78.0907 108.382 74.2639 103.862 69.948Z" fill="url(#paint29_radial_474_227)"/>
        <path d="M74.628 95.8427C70.1496 91.3639 66.8517 87.2107 66.6481 85.9489L60.2967 88.7583C59.5638 89.4907 63.7167 94.9057 69.661 100.809C75.5642 106.713 80.9792 110.906 81.7124 110.174L84.603 103.823C83.463 103.823 79.2286 100.443 74.628 95.8427Z" fill="url(#paint30_linear_474_227)"/>
        <path d="M74.628 95.8427C70.1496 91.3639 66.8517 87.2107 66.6481 85.9489L60.2967 88.7583C59.5638 89.4907 63.7167 94.9057 69.661 100.809C75.5642 106.713 80.9792 110.906 81.7124 110.174L84.603 103.823C83.463 103.823 79.2286 100.443 74.628 95.8427Z" fill="url(#paint31_linear_474_227)"/>
        <path d="M144.455 48.3713C144.536 48.2492 146.165 45.2363 146.205 45.1142C141.645 36.2792 134.153 28.7878 125.318 24.2278C125.197 24.3092 125.075 24.3499 124.952 24.4313C133.625 29.3578 143.437 40.4321 144.455 48.3713Z" fill="url(#paint32_linear_474_227)"/>
        <path opacity="0.75" d="M152.354 18.0781C150.929 16.6531 142.012 15.5539 125.319 24.226C113.064 30.6181 103.456 38.0282 90.5898 50.8939C90.1423 51.3417 89.7348 51.7896 89.2873 52.2374C78.7017 63.1896 71.1287 75.6076 66.6094 85.9489C66.8129 87.2107 70.1108 91.3639 74.5892 95.8426C79.1898 100.443 83.4242 103.823 84.5642 103.823C94.9061 99.3032 107.242 91.7301 118.194 81.1445C118.642 80.697 119.09 80.2895 119.538 79.842C132.363 67.0167 139.814 57.3674 146.206 45.1124C154.919 28.4603 153.819 19.5031 152.354 18.0781Z" fill="url(#paint33_radial_474_227)"/>
        <path d="M64.7759 116.609C78.4557 105.575 89.1639 93.2798 89.4489 92.9542L87.3313 90.8366C87.3313 90.8366 76.1757 103.01 62.8623 114.695L64.7759 116.609Z" fill="url(#paint34_linear_474_227)"/>
        <path d="M87.2904 90.8359C89.7742 86.1134 93.9673 80.1691 99.3416 73.7772C99.2198 73.6547 99.0973 73.5328 98.9348 73.4103C93.1123 78.9478 87.331 83.9553 82.1604 88.3528L76.1348 97.1878L67.0553 106.104C65.4674 110.094 62.7803 114.695 62.7803 114.695C76.1348 103.01 87.2904 90.8359 87.2904 90.8359Z" fill="url(#paint35_linear_474_227)"/>
        <path d="M67.5432 110.46C67.5432 110.46 70.7189 104.719 70.4339 102.847L67.0953 106.144C65.5075 110.134 62.8203 114.735 62.8203 114.735C64.4489 113.269 65.9961 111.844 67.5432 110.46Z" fill="url(#paint36_linear_474_227)"/>
        <path d="M89.4489 92.9556C91.2401 87.5 95.2707 81.3931 100.441 74.7562C100.075 74.4306 99.7495 74.105 99.3833 73.7794C94.0089 80.1712 89.8151 86.1156 87.3313 90.8387C87.3313 90.8387 76.1757 103.012 62.8623 114.697L64.8166 116.611C78.6189 105.455 89.4489 92.9556 89.4489 92.9556Z" fill="url(#paint37_linear_474_227)"/>
        <path d="M64.7759 116.609C78.4557 105.575 89.1639 93.2798 89.4489 92.9542L87.3313 90.8366C87.3313 90.8366 76.1757 103.01 62.8623 114.695L64.7759 116.609Z" fill="url(#paint38_linear_474_227)"/>
        <path opacity="0.75" d="M87.2904 90.8359C89.7742 86.1134 93.9673 80.1691 99.3416 73.7772C99.2198 73.6547 99.0973 73.5328 98.9348 73.4103C93.1123 78.9478 87.331 83.9553 82.1604 88.3528L76.1348 97.1878L67.0553 106.104C65.4674 110.094 62.7803 114.695 62.7803 114.695C76.1348 103.01 87.2904 90.8359 87.2904 90.8359Z" fill="url(#paint39_radial_474_227)"/>
        <path opacity="0.75" d="M152.354 18.0781C150.929 16.6531 142.012 15.5539 125.319 24.226C113.064 30.6181 103.456 38.0282 90.5898 50.8939C90.1423 51.3417 89.7348 51.7896 89.2873 52.2374C78.7017 63.1896 71.1287 75.6076 66.6094 85.9489C66.8129 87.2107 70.1108 91.3639 74.5892 95.8426C79.1898 100.443 83.4242 103.823 84.5642 103.823C94.9061 99.3032 107.242 91.7301 118.194 81.1445C118.642 80.697 119.09 80.2895 119.538 79.842C132.363 67.0167 139.814 57.3674 146.206 45.1124C154.919 28.4603 153.819 19.5031 152.354 18.0781Z" fill="url(#paint40_radial_474_227)"/>
        <g opacity="0.16">
        <path opacity="0.16" d="M123.242 76.1776C117.867 73.002 112.289 68.6863 106.997 63.4342C101.744 58.1821 97.4285 52.6042 94.2529 47.1892" stroke="black" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path opacity="0.16" d="M139.202 47.1509C140.423 48.6573 141.523 50.1231 142.582 51.5887" stroke="black" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <g opacity="0.16">
        <path opacity="0.16" d="M132.605 28.747C132.075 29.1134 131.546 29.5206 131.017 29.9277C120.35 37.9077 111.311 45.9284 98.4859 58.7534C98.0385 59.2013 97.5903 59.6491 97.1835 60.097C96.7759 60.5449 96.3284 60.952 95.9209 61.3999" stroke="black" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        </g>
        <path opacity="0.16" d="M117.582 72.4748C128.859 60.9933 136.066 52.2804 142.865 41.7761C143.15 41.369 143.394 40.9619 143.639 40.5547" stroke="black" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        </g>
        <path opacity="0.75" d="M81.7129 100.931C91.0773 95.475 102.681 87.0881 113.552 76.4612L81.7129 100.931Z" fill="url(#paint41_radial_474_227)"/>
        <path d="M123.689 53.3369C118.477 58.1411 110.742 58.1819 106.425 53.4183C102.11 48.6547 102.884 40.8783 108.095 36.074C113.307 31.2697 121.042 31.229 125.358 35.9926C129.674 40.7561 128.9 48.5326 123.689 53.3369Z" fill="url(#paint42_linear_474_227)"/>
        <path d="M121.449 50.6486C117.174 54.5978 110.823 54.6386 107.281 50.7299C103.738 46.8214 104.349 40.4293 108.664 36.4799C112.94 32.5307 119.291 32.4899 122.833 36.3986C126.335 40.3071 125.724 46.6993 121.449 50.6486Z" fill="url(#paint43_linear_474_227)"/>
        <path d="M120.432 49.5913C116.604 53.1334 110.945 53.1334 107.81 49.6726C104.675 46.1713 105.204 40.512 109.031 36.9698C112.859 33.4277 118.518 33.4277 121.653 36.8884C124.788 40.3492 124.259 46.0491 120.432 49.5913Z" fill="url(#paint44_radial_474_227)"/>
        <path opacity="0.5" d="M120.432 49.5913C116.604 53.1334 110.945 53.1334 107.81 49.6726C104.675 46.1713 105.204 40.512 109.031 36.9698C112.859 33.4277 118.518 33.4277 121.653 36.8884C124.788 40.3492 124.259 46.0491 120.432 49.5913Z" fill="url(#paint45_radial_474_227)"/>
        <path opacity="0.5" d="M120.919 39.4956C122.344 41.0835 123.118 43.0785 123.281 45.1549C124.176 42.2641 123.688 39.1291 121.653 36.8899C118.518 33.3885 112.858 33.4291 109.031 36.9713C108.664 37.3378 108.298 37.7041 107.973 38.1113C112.329 35.4649 117.703 35.9535 120.919 39.4956Z" fill="black"/>
        <path d="M121.653 36.8899C118.518 33.3885 112.859 33.4291 109.031 36.9713C105.204 40.5135 104.675 46.1727 107.81 49.6742C108.135 50.0406 108.461 50.2849 108.258 50.122C105.449 47.3127 106.304 41.5313 109.927 37.9077C112.859 34.9763 118.477 33.9178 121.653 36.8899Z" fill="url(#paint46_linear_474_227)"/>
        <path opacity="0.5" d="M121.653 36.8884C118.478 33.8756 112.818 34.9748 109.928 37.8656C106.467 41.3263 105.531 46.782 107.892 49.7134C108.258 49.917 108.421 50.2834 108.218 50.1206C108.096 49.9984 107.973 49.8763 107.892 49.7134C107.851 49.7134 107.811 49.6727 107.769 49.6727C110.904 53.1742 116.564 53.1334 120.391 49.5913C124.259 46.0491 124.829 40.3898 121.653 36.8884Z" fill="url(#paint47_radial_474_227)"/>
        <path d="M146.285 44.99C141.725 36.155 134.315 28.7043 125.48 24.1443" stroke="#008EE6" stroke-width="0.75" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M118.235 81.1445C113.308 78.0907 108.382 74.2639 103.862 69.948C103.618 69.7036 103.333 69.4594 103.088 69.2151C97.7553 64.0037 92.9509 58.1408 89.3271 52.2372" stroke="#008EE6" stroke-width="0.75" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <defs>
        <linearGradient id="paint0_linear_474_227" x1="73.0946" y1="90.3949" x2="8.26252" y2="175.984" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FF0000"/>
        <stop offset="0.2317" stop-color="#FF1500"/>
        <stop offset="0.7367" stop-color="#FF3D00"/>
        <stop offset="0.9987" stop-color="#FF4C00"/>
        </linearGradient>
        <linearGradient id="paint1_linear_474_227" x1="60.6419" y1="82.3429" x2="-3.40871" y2="166.9" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FF0000"/>
        <stop offset="0.2317" stop-color="#FF1500"/>
        <stop offset="0.7367" stop-color="#FF3D00"/>
        <stop offset="0.9987" stop-color="#FF4C00"/>
        </linearGradient>
        <linearGradient id="paint2_linear_474_227" x1="78.9318" y1="92.982" x2="13.2292" y2="179.719" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FF0000"/>
        <stop offset="0.2317" stop-color="#FF1500"/>
        <stop offset="0.7367" stop-color="#FF3D00"/>
        <stop offset="0.9987" stop-color="#FF4C00"/>
        </linearGradient>
        <linearGradient id="paint3_linear_474_227" x1="60.2498" y1="103.813" x2="18.4984" y2="190.557" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FF8000"/>
        <stop offset="0.6388" stop-color="#FF5B00"/>
        <stop offset="0.9987" stop-color="#FF4C00"/>
        </linearGradient>
        <radialGradient id="paint4_radial_474_227" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(0.621045 149.214) rotate(-135) scale(33.6732 42.7596)">
        <stop offset="0.00134333" stop-color="#FFED1C" stop-opacity="0.6"/>
        <stop offset="0.9477" stop-color="#FFED1C" stop-opacity="0.0264782"/>
        <stop offset="0.9914" stop-color="#FFED1C" stop-opacity="0"/>
        </radialGradient>
        <linearGradient id="paint5_linear_474_227" x1="26.3915" y1="155.485" x2="64.0679" y2="101.461" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FF8000"/>
        <stop offset="0.022669" stop-color="#FE7F02" stop-opacity="0.9773"/>
        <stop offset="0.6663" stop-color="#ED582A" stop-opacity="0.3337"/>
        <stop offset="1" stop-color="#E74939" stop-opacity="0"/>
        </linearGradient>
        <linearGradient id="paint6_linear_474_227" x1="69.6775" y1="99.6061" x2="35.6063" y2="130.132" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FFBC47"/>
        <stop offset="0.1869" stop-color="#FFB137" stop-opacity="0.8131"/>
        <stop offset="0.7223" stop-color="#FF940F" stop-opacity="0.2777"/>
        <stop offset="1" stop-color="#FF8900" stop-opacity="0"/>
        </linearGradient>
        <radialGradient id="paint7_radial_474_227" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(61.0253 108.649) rotate(-135) scale(26.0481 26.8491)">
        <stop offset="0.00134333" stop-color="#FFBC47"/>
        <stop offset="0.2941" stop-color="#FFBF4B" stop-opacity="0.7068"/>
        <stop offset="0.5624" stop-color="#FEC656" stop-opacity="0.4382"/>
        <stop offset="0.8201" stop-color="#FCD368" stop-opacity="0.1801"/>
        <stop offset="1" stop-color="#FBE07A" stop-opacity="0"/>
        </radialGradient>
        <radialGradient id="paint8_radial_474_227" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(60.0639 109.611) rotate(-135) scale(25.2454 26.0218)">
        <stop offset="0.00134333" stop-color="white"/>
        <stop offset="1" stop-color="white" stop-opacity="0"/>
        </radialGradient>
        <radialGradient id="paint9_radial_474_227" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(72.264 113.067) rotate(-135) scale(31.0122 45.8569)">
        <stop offset="0.00134333" stop-color="#FFED1C" stop-opacity="0.6"/>
        <stop offset="0.9477" stop-color="#FFED1C" stop-opacity="0.0264782"/>
        <stop offset="0.9914" stop-color="#FFED1C" stop-opacity="0"/>
        </radialGradient>
        <radialGradient id="paint10_radial_474_227" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(60.4469 109.405) rotate(-42.7273) scale(12.4442 7.96069)">
        <stop offset="0.00134333" stop-color="white"/>
        <stop offset="1" stop-color="white" stop-opacity="0"/>
        </radialGradient>
        <radialGradient id="paint11_radial_474_227" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(18.1143 143.898) rotate(-135) scale(11.1253 14.1274)">
        <stop offset="0.00134333" stop-color="#FFED1C"/>
        <stop offset="0.1997" stop-color="#FFEE2B" stop-opacity="0.8013"/>
        <stop offset="0.5839" stop-color="#FFF254" stop-opacity="0.4167"/>
        <stop offset="1" stop-color="#FFF686" stop-opacity="0"/>
        </radialGradient>
        <radialGradient id="paint12_radial_474_227" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(36.0628 151.933) rotate(162.598) scale(10.7593 2.17701)">
        <stop offset="0.00134333" stop-color="#FFED1C" stop-opacity="0.6"/>
        <stop offset="0.9477" stop-color="#FFED1C" stop-opacity="0.0264782"/>
        <stop offset="0.9914" stop-color="#FFED1C" stop-opacity="0"/>
        </radialGradient>
        <radialGradient id="paint13_radial_474_227" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(28.4445 156.979) rotate(-41.019) scale(10.2693 3.26274)">
        <stop offset="0.00134333" stop-color="#FFED1C"/>
        <stop offset="0.1997" stop-color="#FFEE2B" stop-opacity="0.8013"/>
        <stop offset="0.5839" stop-color="#FFF254" stop-opacity="0.4167"/>
        <stop offset="1" stop-color="#FFF686" stop-opacity="0"/>
        </radialGradient>
        <radialGradient id="paint14_radial_474_227" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(18.5799 146.927) rotate(-135) scale(34.7144 44.0817)">
        <stop offset="0.00134333" stop-color="#FF4C00"/>
        <stop offset="0.135" stop-color="#FF5B0C" stop-opacity="0.8661"/>
        <stop offset="0.3941" stop-color="#FF842C" stop-opacity="0.6067"/>
        <stop offset="0.7491" stop-color="#FFC45F" stop-opacity="0.2512"/>
        <stop offset="1" stop-color="#FFF686" stop-opacity="0"/>
        </radialGradient>
        <radialGradient id="paint15_radial_474_227" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(32.6684 110.509) rotate(-135) scale(18.4258 23.3979)">
        <stop offset="0.00134333" stop-color="#FFED1C"/>
        <stop offset="0.1997" stop-color="#FFEE2B" stop-opacity="0.8013"/>
        <stop offset="0.5839" stop-color="#FFF254" stop-opacity="0.4167"/>
        <stop offset="1" stop-color="#FFF686" stop-opacity="0"/>
        </radialGradient>
        <radialGradient id="paint16_radial_474_227" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(60.0459 111.359) rotate(-135) scale(38.5307 48.9278)">
        <stop offset="0.00134333" stop-color="#FFED1C"/>
        <stop offset="0.1997" stop-color="#FFEE2B" stop-opacity="0.8013"/>
        <stop offset="0.5839" stop-color="#FFF254" stop-opacity="0.4167"/>
        <stop offset="1" stop-color="#FFF686" stop-opacity="0"/>
        </radialGradient>
        <linearGradient id="paint17_linear_474_227" x1="69.8512" y1="137.257" x2="111.606" y2="92.2281" gradientUnits="userSpaceOnUse">
        <stop stop-color="#BD2719"/>
        <stop offset="0.2151" stop-color="#D41A10"/>
        <stop offset="0.5049" stop-color="#EC0C07"/>
        <stop offset="0.774" stop-color="#FA0302"/>
        <stop offset="1" stop-color="#FF0000"/>
        </linearGradient>
        <linearGradient id="paint18_linear_474_227" x1="94.1272" y1="116.158" x2="81.0279" y2="129.024" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FC8D41"/>
        <stop offset="0.0900324" stop-color="#FC7837"/>
        <stop offset="0.294" stop-color="#FD4D24"/>
        <stop offset="0.4922" stop-color="#FE2C14"/>
        <stop offset="0.6802" stop-color="#FF1409"/>
        <stop offset="0.854" stop-color="#FF0502"/>
        <stop offset="1" stop-color="#FF0000"/>
        </linearGradient>
        <radialGradient id="paint19_radial_474_227" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(63.8286 131.571) rotate(45) scale(56.49)">
        <stop offset="0.00134333" stop-color="#FFED1C"/>
        <stop offset="0.1997" stop-color="#FFEE2B" stop-opacity="0.8013"/>
        <stop offset="0.5839" stop-color="#FFF254" stop-opacity="0.4167"/>
        <stop offset="1" stop-color="#FFF686" stop-opacity="0"/>
        </radialGradient>
        <linearGradient id="paint20_linear_474_227" x1="95.1041" y1="101.889" x2="159.821" y2="26.8784" gradientUnits="userSpaceOnUse">
        <stop stop-color="#BD2719"/>
        <stop offset="0.2151" stop-color="#D41A10"/>
        <stop offset="0.5049" stop-color="#EC0C07"/>
        <stop offset="0.774" stop-color="#FA0302"/>
        <stop offset="1" stop-color="#FF0000"/>
        </linearGradient>
        <linearGradient id="paint21_linear_474_227" x1="29.6817" y1="84.9856" x2="69.1129" y2="65.6339" gradientUnits="userSpaceOnUse">
        <stop stop-color="#BD2719"/>
        <stop offset="0.2151" stop-color="#D41A10"/>
        <stop offset="0.5049" stop-color="#EC0C07"/>
        <stop offset="0.774" stop-color="#FA0302"/>
        <stop offset="1" stop-color="#FF0000"/>
        </linearGradient>
        <linearGradient id="paint22_linear_474_227" x1="52.1913" y1="73.5606" x2="39.7936" y2="88.2973" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FC8D41"/>
        <stop offset="0.0900324" stop-color="#FC7837"/>
        <stop offset="0.294" stop-color="#FD4D24"/>
        <stop offset="0.4922" stop-color="#FE2C14"/>
        <stop offset="0.6802" stop-color="#FF1409"/>
        <stop offset="0.854" stop-color="#FF0502"/>
        <stop offset="1" stop-color="#FF0000"/>
        </linearGradient>
        <radialGradient id="paint23_radial_474_227" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(58.2155 109.113) rotate(45) scale(60.7606)">
        <stop offset="0.00134333" stop-color="#FFED1C"/>
        <stop offset="0.1997" stop-color="#FFEE2B" stop-opacity="0.8013"/>
        <stop offset="0.5839" stop-color="#FFF254" stop-opacity="0.4167"/>
        <stop offset="1" stop-color="#FFF686" stop-opacity="0"/>
        </radialGradient>
        <linearGradient id="paint24_linear_474_227" x1="84.2094" y1="65.3871" x2="20.6153" y2="68.3609" gradientUnits="userSpaceOnUse">
        <stop stop-color="#BD2719"/>
        <stop offset="0.2151" stop-color="#D41A10"/>
        <stop offset="0.5049" stop-color="#EC0C07"/>
        <stop offset="0.774" stop-color="#FA0302"/>
        <stop offset="1" stop-color="#FF0000"/>
        </linearGradient>
        <linearGradient id="paint25_linear_474_227" x1="107.218" y1="38.1254" x2="132.81" y2="63.7177" gradientUnits="userSpaceOnUse">
        <stop stop-color="#BBCACC"/>
        <stop offset="0.3717" stop-color="white"/>
        <stop offset="1" stop-color="#99AFB3"/>
        </linearGradient>
        <linearGradient id="paint26_linear_474_227" x1="150.598" y1="36.3918" x2="136.665" y2="18.3106" gradientUnits="userSpaceOnUse">
        <stop stop-color="#008EE6"/>
        <stop offset="0.688" stop-color="#80DAFE"/>
        <stop offset="0.7325" stop-color="#76D5FB"/>
        <stop offset="0.8071" stop-color="#5CC8F3"/>
        <stop offset="0.9026" stop-color="#32B2E6"/>
        <stop offset="1" stop-color="#0099D6"/>
        </linearGradient>
        <linearGradient id="paint27_linear_474_227" x1="101.579" y1="93.5377" x2="72.4951" y2="64.6101" gradientUnits="userSpaceOnUse">
        <stop stop-color="#008EE6"/>
        <stop offset="0.688" stop-color="#80DAFE"/>
        <stop offset="0.7325" stop-color="#76D5FB"/>
        <stop offset="0.8071" stop-color="#5CC8F3"/>
        <stop offset="0.9026" stop-color="#32B2E6"/>
        <stop offset="1" stop-color="#0099D6"/>
        </linearGradient>
        <radialGradient id="paint28_radial_474_227" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(81.8346 103.13) rotate(45) scale(27.6734 50.6503)">
        <stop stop-opacity="0.75"/>
        <stop offset="1" stop-opacity="0"/>
        </radialGradient>
        <radialGradient id="paint29_radial_474_227" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(114.084 96.6107) rotate(45) scale(27.6734 50.6503)">
        <stop stop-opacity="0.75"/>
        <stop offset="1" stop-opacity="0"/>
        </radialGradient>
        <linearGradient id="paint30_linear_474_227" x1="63.9156" y1="85.7024" x2="82.1577" y2="109.655" gradientUnits="userSpaceOnUse">
        <stop/>
        <stop offset="0.1962" stop-color="#4D4D4C"/>
        <stop offset="0.7655"/>
        </linearGradient>
        <linearGradient id="paint31_linear_474_227" x1="63.9156" y1="85.7024" x2="82.1577" y2="109.655" gradientUnits="userSpaceOnUse">
        <stop stop-opacity="0"/>
        <stop offset="0.1975" stop-color="#CFDCDE" stop-opacity="0.5"/>
        <stop offset="0.7655" stop-opacity="0"/>
        </linearGradient>
        <linearGradient id="paint32_linear_474_227" x1="102.125" y1="28.8201" x2="143.958" y2="38.3718" gradientUnits="userSpaceOnUse">
        <stop stop-color="#BBCACC"/>
        <stop offset="0.3717" stop-color="white"/>
        <stop offset="1" stop-color="#99AFB3"/>
        </linearGradient>
        <radialGradient id="paint33_radial_474_227" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(114.542 81.2307) rotate(44.2342) scale(17.855 57.82)">
        <stop stop-color="#440063" stop-opacity="0.75"/>
        <stop offset="1" stop-color="#420061" stop-opacity="0"/>
        </radialGradient>
        <linearGradient id="paint34_linear_474_227" x1="61.7079" y1="112.978" x2="109.779" y2="81.9831" gradientUnits="userSpaceOnUse">
        <stop stop-color="#BD2719"/>
        <stop offset="0.2151" stop-color="#D41A10"/>
        <stop offset="0.5049" stop-color="#EC0C07"/>
        <stop offset="0.774" stop-color="#FA0302"/>
        <stop offset="1" stop-color="#FF0000"/>
        </linearGradient>
        <linearGradient id="paint35_linear_474_227" x1="53.8987" y1="114.611" x2="78.6913" y2="95.735" gradientUnits="userSpaceOnUse">
        <stop stop-color="#BD2719"/>
        <stop offset="0.2151" stop-color="#D41A10"/>
        <stop offset="0.5049" stop-color="#EC0C07"/>
        <stop offset="0.774" stop-color="#FA0302"/>
        <stop offset="1" stop-color="#FF0000"/>
        </linearGradient>
        <linearGradient id="paint36_linear_474_227" x1="77.4815" y1="98.7204" x2="63.2902" y2="111.976" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FC8D41"/>
        <stop offset="0.0900324" stop-color="#FC7837"/>
        <stop offset="0.294" stop-color="#FD4D24"/>
        <stop offset="0.4922" stop-color="#FE2C14"/>
        <stop offset="0.6802" stop-color="#FF1409"/>
        <stop offset="0.854" stop-color="#FF0502"/>
        <stop offset="1" stop-color="#FF0000"/>
        </linearGradient>
        <linearGradient id="paint37_linear_474_227" x1="77.0172" y1="107.909" x2="93.835" y2="59.7813" gradientUnits="userSpaceOnUse">
        <stop stop-color="#BD2719"/>
        <stop offset="0.2151" stop-color="#D41A10"/>
        <stop offset="0.5049" stop-color="#EC0C07"/>
        <stop offset="0.774" stop-color="#FA0302"/>
        <stop offset="1" stop-color="#FF0000"/>
        </linearGradient>
        <linearGradient id="paint38_linear_474_227" x1="61.7079" y1="112.978" x2="109.779" y2="81.9831" gradientUnits="userSpaceOnUse">
        <stop stop-color="#BD2719"/>
        <stop offset="0.2151" stop-color="#D41A10"/>
        <stop offset="0.5049" stop-color="#EC0C07"/>
        <stop offset="0.774" stop-color="#FA0302"/>
        <stop offset="1" stop-color="#FF0000"/>
        </linearGradient>
        <radialGradient id="paint39_radial_474_227" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(61.2919 108.765) rotate(-39.0499) scale(42.5736 14.2767)">
        <stop offset="0.00134333" stop-color="#FFED1C"/>
        <stop offset="0.1997" stop-color="#FFEE2B" stop-opacity="0.8013"/>
        <stop offset="0.5839" stop-color="#FFF254" stop-opacity="0.4167"/>
        <stop offset="1" stop-color="#FFF686" stop-opacity="0"/>
        </radialGradient>
        <radialGradient id="paint40_radial_474_227" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(102.191 52.1186) rotate(44.2342) scale(17.855 57.82)">
        <stop stop-color="white" stop-opacity="0.5"/>
        <stop offset="1" stop-color="white" stop-opacity="0"/>
        </radialGradient>
        <radialGradient id="paint41_radial_474_227" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(98.2336 92.9691) rotate(43.8644) scale(4.3352 20.8159)">
        <stop stop-color="#440063" stop-opacity="0.75"/>
        <stop offset="1" stop-color="#420061" stop-opacity="0"/>
        </radialGradient>
        <linearGradient id="paint42_linear_474_227" x1="109.149" y1="59.2183" x2="122.573" y2="30.3102" gradientUnits="userSpaceOnUse">
        <stop stop-color="#BD2719"/>
        <stop offset="0.2151" stop-color="#D41A10"/>
        <stop offset="0.5049" stop-color="#EC0C07"/>
        <stop offset="0.774" stop-color="#FA0302"/>
        <stop offset="1" stop-color="#FF0000"/>
        </linearGradient>
        <linearGradient id="paint43_linear_474_227" x1="118.717" y1="31.3716" x2="111.407" y2="55.6407" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FC8D41"/>
        <stop offset="0.0900324" stop-color="#FC7837"/>
        <stop offset="0.294" stop-color="#FD4D24"/>
        <stop offset="0.4922" stop-color="#FE2C14"/>
        <stop offset="0.6802" stop-color="#FF1409"/>
        <stop offset="0.854" stop-color="#FF0502"/>
        <stop offset="1" stop-color="#FF0000"/>
        </linearGradient>
        <radialGradient id="paint44_radial_474_227" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(111.297 43.6572) rotate(44.996) scale(23.6097 23.608)">
        <stop stop-color="#512D00"/>
        <stop offset="1"/>
        </radialGradient>
        <radialGradient id="paint45_radial_474_227" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(115.894 38.9835) scale(20.7134)">
        <stop offset="0.4873" stop-color="#F5CBC0" stop-opacity="0"/>
        <stop offset="1" stop-color="#E67E62"/>
        </radialGradient>
        <linearGradient id="paint46_linear_474_227" x1="113.976" y1="36.072" x2="114.858" y2="20.4135" gradientUnits="userSpaceOnUse">
        <stop stop-color="#BD2719"/>
        <stop offset="0.0422031" stop-color="#B42717"/>
        <stop offset="0.257" stop-color="#89250F"/>
        <stop offset="0.4654" stop-color="#682408"/>
        <stop offset="0.6634" stop-color="#502304"/>
        <stop offset="0.8464" stop-color="#412201"/>
        <stop offset="1" stop-color="#3C2200"/>
        </linearGradient>
        <radialGradient id="paint47_radial_474_227" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(86.6723 33.9111) scale(28.0881)">
        <stop stop-color="white" stop-opacity="0.5"/>
        <stop offset="1" stop-color="white" stop-opacity="0"/>
        </radialGradient>
        </defs>
        </svg>
        </Tilt>
        </div>     
        </Col>

        <Col>
        <div id="studentsitemtext">
        <h2 id="bold" className="center">Inclusivity</h2>
        <p id="centertextchunk">No gatekeeping here :) We want to give everyone toolkits and opportunities to explore and break into product management.</p>
        </div>
        </Col>
        
        </Fade>
      </Row>
    </Container>


    <div id="divmesomespace"></div>
    <Zoom top>
    <div id="centereddiv"><Button id="bigbutton" href="/getinvolved">Get involved</Button></div>
    </Zoom>
    </div>
        )
}

export default Students;